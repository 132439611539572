import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ButtonIconOutLine,
  CheckboxToggle,
  Div,
  If,
  Image,
  InputDefault,
  NavbarTab,
  Notification,
  Row,
  Select,
  SelectValue,
  TextAreaCustom,
  Title,
} from "../../components";
import { Color, Mask, Utils } from "../../utilities";
import { bindActionCreators } from "redux";
import { setEmpresa } from "../../redux/actions";
import FileUpload from "../../components/forms/input/FileUpload";
import ImagemIndisponivel from "../../assets/images/imgindisponivel_thumb.webp";
import EmpresaService from "../../service/EmpresaService";
import BackgroundImg from "../../assets/images/background.webp";

const styles = {
  boxInfo: {
    marginBottom: "50px",
    padding: "10px 0 20px 0",
    backgroundColor: "#FFF",
    alignItems: "end",
  },
  labelCinza: {
    color: "#878787",
    fontSize: "16px",
    marginBottom: "35px",
  },
  imageIcon: {
    marginBottom: 15,
    marginLeft: 5,
    marginRight: 5,
  },
  checkedBox: {
    margin: "8px 5px",
  },
  colorInput: {
    color: Color.ECOMMERCE.CINZA_ESCURO,
  },
};

class Banners extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemActive: {
        target: "PRINCIPAL",
        value: "PRINCIPAL",
        active: true,
      },
      idEmpresa: "",
      emailAtendimento: "",
      horarioFuncionamento: "",
      tituloPagina: "",
      empresaParams: {},
      empresa: {},
      aceitaPagamentoOnline: false,
      pagamentoOnlineTipo: null,
      pagamentoOnlineTipoOptions: [
        new SelectValue(null, "SELECIONE", true),
        new SelectValue("CIELO", "CIELO", true),
      ],
      urlFoto: "",
      urlFavIcon: "",
      urlLogoEmail: "",
      urlBackground: "",
      arquivourlFoto: null,
      arquivourlFavIcon: null,
      arquivourlLogoEmail: null,
      arquivourlBackground: null,
      obsRodape: "",
      sobre: "",
      politica: "",
      telefoneAtendimento: "",
      telefoneWhatsapp: "",
      urlAppStore: "",
      urlFacebook: "",
      urlInstagram: "",
      urlPlayStore: "",

      chavePix: "",
    };
  }

  // componentDidMount() {
  //   if (Object.keys(this.props.empresa).length !== 0) {
  //     this.setState({
  //       empresa: { ...this.props.empresa },
  //     });
  //   }

  //   this.getParametrosEmpresa();
  //   this.getEmpresaPolitica();
  //   this.getEmpresaSobre();
  // }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.empresa !== this.props.empresa) {
  //     this.setState({
  //       empresa: { ...this.props.empresa },
  //     });
  //   }
  // }

  async getParametrosEmpresa() {
    await EmpresaService.getEmpresaParams().then(({ data }) => {
      this.setState({
        ...data,
      });
    });
  }

  async getEmpresaSobre() {
    await EmpresaService.getSobre().then(({ data }) => {
      this.setState({
        sobre: data.sobre,
      });
    });
  }

  async getEmpresaPolitica() {
    await EmpresaService.getPolitica().then(({ data }) => {
      this.setState({
        politica: data.politica,
      });
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleFileUpload = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  handleChanceTextArea = (name, value) => {
    this.setState({
      [name]: value,
      [`arquivo${name}`]: value,
    });
  };

  submitParametros = () => {
    const {
      empresa,
      horarioFuncionamento,
      tituloPagina,
      emailAtendimento,
      arquivourlFoto,
      arquivourlBackground,
      arquivourlFavIcon,
      arquivourlLogoEmail,
      obsRodape,
      sobre,
      politica,
      urlAppStore,
      urlFacebook,
      urlInstagram,
      urlPlayStore,
      chavePix,
    } = this.state;

    let newParams = {
      idEmpresa: this.state.idEmpresa,
      horarioFuncionamento,
      tituloPagina,
      emailAtendimento,
      arquivoLogoEmpresa: arquivourlFoto,
      arquivoLogoEmpresaEmail: arquivourlLogoEmail,
      arquivoFavIcon: arquivourlFavIcon,
      arquivoBackground: arquivourlBackground,
      obsRodape,
      sobre,
      politica,
      urlAppStore,
      urlFacebook,
      urlInstagram,
      urlPlayStore,
      chavePix,
    };

    EmpresaService.putEmpresa(newParams)
      .then(({ data, status }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          // this.props.setLoadingProdutosPreco(true);
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => {
        Notification.danger("Problemas na Atualização das informações gerais!");
      });
  };

  handleChangeCheckedPagamento = (e) => {
    const { name, checked } = e;
    this.setState({ [name]: checked });
  };

  getParametros = (empresa) => {
    let parametros = {};
    if (Utils.isValueValid(empresa)) {
      parametros = { ...empresa.parametros };
    }

    return parametros;
  };

  getItens = () => {
    let item = this.state.itemActive;

    return [
      {
        target: "PRINCIPAL",
        value: "BANNER PRINCIPAL",
        active: item.value === "PRINCIPAL",
      },

      {
        target: "ANUNCIO",
        value: "ANUNCIO PRINCIPAL",
        active: item.value === "ANUNCIO",
      },

      {
        target: "ANUNCIO_2",
        value: "ANUNCIO SECUNDARIO",
        active: item.value === "ANUNCIO",
      },
    ];
  };

  render() {
    let { target } = this.state.itemActive;
    return (
      <Div>
        <Div col="12">
          {/* <Title
            value="Personalização dos Banners"
            type="h6"
            className="label-valor-total-carrinho"
            style={styles.labelCinza}
            family="Bold"
            col="12"
          /> */}

          <NavbarTab
            itens={this.getItens()}
            handlerClick={(itemActive) => {
              this.setState({ itemActive });
            }}
          />

          <Row className="border-left-primary" padding="2">
            <If and value1={target === "PRINCIPAL"}>
              <Div shadow col="12" style={styles.boxInfo}>
                <Title
                  value="Banner principal deve ter as medidas de 1536X530"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={{ ...styles.labelCinza, marginLeft: 10 }}
                  family="Bold"
                  col="12"
                />

                <Image
                  src={
                    "https://www.imgs.lojainstantanea.com.br/mercasimples/b73803e4-b193-11eb-8320-0242ac320002/anuncios/20250131-banner-nivea.webp"
                  }
                  style={{ ...styles.imageIcon, marginLeft: 25 }}
                  //className="color-icon-menu"
                  pointer
                  width="50%"
                />
              </Div>
              <Row shadow col="12" style={styles.boxInfo}>
                <Title
                  value="Banner Principal"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={styles.labelCinza}
                  family="Bold"
                  col="12"
                />
                <Div col="3">
                  <Div
                    col="12"
                    style={{
                      textAlign: "center",
                      background: `url(${BackgroundImg})`,
                      backgroundSize: "cover",
                    }}
                  >
                    <Image
                      src={
                        this.state.urlFoto
                          ? this.state.urlFoto
                          : ImagemIndisponivel
                      }
                      style={styles.imageIcon}
                      //className="color-icon-menu"
                      pointer
                      width="100%"
                    />
                  </Div>
                  <FileUpload
                    col="12"
                    label="Banner 1"
                    handlerChange={(e) =>
                      this.handleFileUpload({
                        name: "urlFoto",
                        value: e.fileUpload,
                      })
                    }
                    isIcon
                    classNameIcon="col-12 btn bg-primary text-white rounded pt-3"
                    name="urlFoto"
                    tipos={["png"]}
                  ></FileUpload>
                </Div>
              </Row>
            </If>
          </Row>
          <Row className="border-left-primary" padding="2">
            <If and value1={target === "ANUNCIO"}>
              <Div shadow col="12" style={styles.boxInfo}>
                <Title
                  value="O Anuncio principal deve ter as medidas de 527X444"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={{ ...styles.labelCinza, marginLeft: 10 }}
                  family="Bold"
                  col="12"
                />

                <Image
                  src={
                    "https://www.imgs.lojainstantanea.com.br/mercasimples/b73803e4-b193-11eb-8320-0242ac320002/anuncios/20230109-topo-alimentos.webp"
                  }
                  style={{ ...styles.imageIcon, marginLeft: 25 }}
                  //className="color-icon-menu"
                  pointer
                  width="20%"
                />
              </Div>
              <Row shadow col="12" style={styles.boxInfo}>
                <Title
                  value="Anúncios"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={styles.labelCinza}
                  family="Bold"
                  col="12"
                />
                <Div col="3">
                  <Div
                    col="12"
                    style={{
                      textAlign: "center",
                      background: `url(${BackgroundImg})`,
                      backgroundSize: "cover",
                    }}
                  >
                    <Image
                      src={
                        this.state.urlFoto
                          ? this.state.urlFoto
                          : ImagemIndisponivel
                      }
                      style={styles.imageIcon}
                      //className="color-icon-menu"
                      pointer
                      width="100%"
                    />
                  </Div>
                  <FileUpload
                    col="12"
                    label="Anúncios principal"
                    handlerChange={(e) =>
                      this.handleFileUpload({
                        name: "urlFoto",
                        value: e.fileUpload,
                      })
                    }
                    isIcon
                    classNameIcon="col-12 btn bg-primary text-white rounded pt-3"
                    name="urlFoto"
                    tipos={["png"]}
                  ></FileUpload>
                </Div>
              </Row>
            </If>
          </Row>

          <Row className="border-left-primary" padding="2">
            <If and value1={target === "ANUNCIO_2"}>
              <Div shadow col="12" style={styles.boxInfo}>
                <Title
                  value="O Anúncio secundário deve ter as medidas de 260X402"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={{ ...styles.labelCinza, marginLeft: 10 }}
                  family="Bold"
                  col="12"
                />

                <Image
                  src={
                    "https://www.imgs.lojainstantanea.com.br/mercasimples/b73803e4-b193-11eb-8320-0242ac320002/anuncios/20230109-topo-higieneelimpeza.webp"
                  }
                  style={{ ...styles.imageIcon, marginLeft: 25 }}
                  //className="color-icon-menu"
                  pointer
                  width="20%"
                />
              </Div>
              <Row shadow col="12" style={styles.boxInfo}>
                <Title
                  value="Anúncios"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={styles.labelCinza}
                  family="Bold"
                  col="12"
                />
                <Div col="3">
                  <Div
                    col="12"
                    style={{
                      textAlign: "center",
                      background: `url(${BackgroundImg})`,
                      backgroundSize: "cover",
                    }}
                  >
                    <Image
                      src={
                        this.state.urlFoto
                          ? this.state.urlFoto
                          : ImagemIndisponivel
                      }
                      style={styles.imageIcon}
                      //className="color-icon-menu"
                      pointer
                      width="100%"
                    />
                  </Div>
                  <FileUpload
                    col="12"
                    label="Anúncios secundário"
                    handlerChange={(e) =>
                      this.handleFileUpload({
                        name: "urlFoto",
                        value: e.fileUpload,
                      })
                    }
                    isIcon
                    classNameIcon="col-12 btn bg-primary text-white rounded pt-3"
                    name="urlFoto"
                    tipos={["png"]}
                  ></FileUpload>
                </Div>
              </Row>
            </If>
          </Row>

          <Div
            col="12"
            style={{
              textAlign: "right",
              alignItems: "center",
              display: "flex",
              justifyContent: "flex-end ",
              margin: "-25px 0 35px 0",
              padding: "0",
            }}
          >
            <ButtonIconOutLine
              value="Salvar"
              //icon={Type.ICON.PLUS}
              btn={Color.NODE.PRIMARY}
              handlerClick={() => this.submitParametros()}
            />
          </Div>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
  ...store.empresaState,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setEmpresa }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Banners);
