import { Http } from "../utilities";

class ProdutoCataloService {
  static async getResumoProdutos() {
    let res = null;
    await Http.get(`/produto/resumo/`).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async getProdutos(idProduto) {
    let res = null;
    await Http.get(`/produto/completo/${idProduto}`).then((response) => {
      res = response;
    });
    return res;
  }

  static async getProdutosFiltros() {
    let res = null;
    await Http.get(`/produto/filtros`).then((response) => {
      res = response;
    });
    return res;
  }

  static async getDepartamentoSecaoFiltro() {
    let res = null;
    await Http.get(`/departamentosecao`).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async getDepartamentoSecaoPersonalizado(idFilial) {
    let res = null;
    await Http.get(`/departamentosecao/personalizado/filial/${idFilial}`).then(
      (response) => {
        res = response.data;
      }
    );
    return res;
  }

  static async consultarProdutosFiltro(obj) {
    let res = null;

    await Http.post("/produto/busca/filtro/admin", obj).then((response) => {
      res = response.data;
    });

    return res;
  }

  static async atualizarPersonalizacao(obj) {
    let res = null;

    await Http.post("/produto/catalogo/sincronizar", obj).then((response) => {
      res = response.data;
    });

    return res;
  }

  static async alterarSituacao(obj) {
    let res = null;

    await Http.post("/produto/situacao/atualizar/emmassa", obj).then(
      (response) => {
        res = response.data;
      }
    );

    return res;
  }

  static async alterarDepartamentoSec(obj) {
    let res = null;

    await Http.put("/produto/departamentosecao", obj).then((response) => {
      res = response.data;
    });

    return res;
  }

  static async criarNovoFiltro(obj) {
    let res = null;

    await Http.post("/produto/filtro", obj).then((response) => {
      res = response.data;
    });

    return res;
  }

  static async removerFiltro(obj) {
    let res = null;

    await Http.delete("/produto/filtro", obj).then((response) => {
      res = response.data;
    });

    return res;
  }

  static async atualizarFiltro(obj) {
    let res = null;

    await Http.put("/produto/filtro", obj).then((response) => {
      res = response.data;
    });

    return res;
  }

  static async atualizarProdutoAdmin(obj) {
    let res = null;

    await Http.put("/produto", obj).then((response) => {
      res = response.data;
    });

    return res;
  }
}

export default ProdutoCataloService;
