import React, { Component } from "react";
import { EmpresaUtils, ProdutoUtils } from "..";
import { Div, I, If, Image, Label, ModalNotification } from "../../components";
import { Color, Mask, Type, Utils } from "../../utilities";
import LoginCliente from "../cliente/LoginCliente";
import RecuperarSenha from "../cliente/RecuperarSenha";
import CarrinhoUtils from "../utils/CarrinhoUtils";
import UsuarioUtils from "../utils/UsuarioUtils";
import LazyLoad from "react-lazyload";

import "./produtos.css";

const BASE = Utils.isViewMobileLarguraPequeno() ? 2.9 : 3.5;
const WIDTH = window.innerWidth / BASE;
const WIDTH_TABLET = window.innerWidth / 6;
const HEIGHT = 95;

const getWidth = () => {
  return Utils.isViewTabletGrande()
    ? window.innerWidth / 8
    : Utils.isViewTablet() || Utils.isViewMobileGrande()
      ? WIDTH_TABLET
      : WIDTH;
};

const styles = {
  descricao: {
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: "12px",
    lineHeight: "12px",
    height: 25,
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
    alignItems: "center",
  },
  descricaoLogin: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "bold",
    textAlign: "center",
    alignItems: "center",
    margin: "20px 0px",
    backgroundColor: "#f2f2f2",
    padding: 10,
    borderRadius: 10,
  },
  precoUnitario: {
    marginLeft: 10,
    color: Color.ECOMMERCE.LARANJA,
    width: 100,
    borderRadius: 5,
    lineHeight: "5px",
    fontSize: 10,
    textDecoration: "line-through",
  },
  precoVenda: {
    marginLeft: 10,
    borderRadius: 5,
    lineHeight: "5px",
    color: Color.ECOMMERCE.LARANJA,
    width: 100,
    fontSize: 12,
  },
  precoVendaIndisponivel: {
    textDecoration: "line-through",
  },
  precoAtacado: {
    fontWeight: "bold",
    color: "#46160E",
    fontSize: 10,
    marginLeft: 10,
  },
  label: {
    marginLeft: 10,
    marginTop: 100,
    color: Color.ECOMMERCE.LARANJA,
    width: getWidth(),
    fontWeight: "bold",
  },
  containerPercentualEconomia: {
    backgroundColor: "#31CDFF",
    color: "white",
    borderRadius: 50,
    lineHeight: "8px",
    paddingLeft: 5,
    paddingRight: 5,
    position: "absolute",
    textAlign: "center",
  },
};

class ItemProdutoAbaixouPrecoMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      indisponivel: false,
      quantidade: 0,
      imagem: this.props.image,
    };

    this.thisUpdate = this.thisUpdate.bind(this);

    document.addEventListener("alterarQuantidadeProduto", (event) => {
      let details = event.detail;
      if (
        Utils.isValueValid(details) &&
        String(details.codigoBarras) === String(props.codigoBarras)
      ) {
        this.consultarProdutoCarrinho(this.props.codigoBarras);
      }
    });

    this.adicionarItem = this.adicionarItem.bind(this);
    this.removerItem = this.removerItem.bind(this);
  }

  setProdutoIndisponivel(produto) {
    let precoValido = Number(produto.precoVenda) > 0;
    if (!precoValido) {
      this.setState({ indisponivel: true });
    }
  }

  componentDidMount() {
    this.consultarProdutoCarrinho(this.props.codigoBarras);
    this.setProdutoIndisponivel({ precoVenda: this.props.precoVenda });
  }

  UNSAFE_componentWillReceiveProps({ codigoBarras, precoVenda, image }) {
    this.consultarProdutoCarrinho(codigoBarras);
    this.setProdutoIndisponivel({ precoVenda });

    if (this.props.image !== image) {
      this.setState({ imagem: image });
    }
  }

  getDescricaoProduto(descricao) {
    let descricoes = String(descricao).split(" ");
    let descricaoNormal = "";

    Array.from(descricoes).forEach((desc, index) => {
      if (index < 4) {
        descricaoNormal = descricaoNormal.concat(" ").concat(desc);
      }
    });

    return descricaoNormal;
  }

  usuarioEstaLogado() {
    let usuario = UsuarioUtils.getUsuarioLojaWeb();
    return Utils.isValueValid(usuario);
  }

  renderViewRecuperarSenha(corPrimaria) {
    ModalNotification.renderModalLogin(
      <>
        <Label
          className="modal-title"
          style={{
            color: corPrimaria,
            fontSize: 25,
            marginTop: 20,
          }}
          family="Bold"
          value="Recuperar senha"
        />
        <Label
          value="Informe o usuário (email)"
          style={{ color: corPrimaria }}
          family="Light"
        />
      </>,
      <RecuperarSenha corPrimaria={corPrimaria} />
    );
  }

  renderViewLogin(callback, corPrimaria) {
    ModalNotification.renderModalLogin(
      <>
        <Label
          className="modal-title"
          style={{
            color: corPrimaria,
            fontSize: 25,
            marginTop: 20,
          }}
          family="Bold"
          value="Dados de acesso"
        />
        <Label
          value="Esqueci meus dados!"
          style={{
            color: Color.EMPRESA.TERCIARIA,
          }}
          className="hover-link"
          family="Light"
          handlerClick={() => this.renderViewRecuperarSenha(corPrimaria)}
        />
      </>,
      <LoginCliente
        handlerConfirm={() => {
          callback();
          Utils.refreshPage();
        }}
        empresa={this.props.empresa}
        {...this.props}
        corPrimaria={corPrimaria}
      />
    );
  }

  consultarProdutoCarrinho(codigoBarras) {
    let carrinho = CarrinhoUtils.getCarrinho();
    if (Utils.isValueValid(carrinho)) {
      let itens = Array.from(carrinho.itens || []);
      let produtoNoCarrinho = itens.filter(
        (item) => item.codigoBarras === codigoBarras
      )[0];
      if (Utils.isValueValid(produtoNoCarrinho)) {
        this.setState({ quantidade: produtoNoCarrinho.quantidade });
      } else {
        this.setState({ quantidade: 0 });
      }
    } else {
      this.setState({ quantidade: 0 });
    }
  }

  isProdutoValido(produto) {
    return ProdutoUtils.validarPreco(
      produto,
      "Produto indisponível , não possui preço de venda"
    );
  }

  renderProdutoIndisponivel() {
    return (
      <Div
        className={
          this.state.indisponivel
            ? "view-opaca-item-mobile-indisponivel"
            : "view-opaca-item-mobile"
        }
        style={{
          width: getWidth(),
          borderRadius: 10,
          height: HEIGHT,
        }}
      >
        <Div
          style={{
            fontSize: 11,
            transform: `translateY(${HEIGHT / 2.5}px)`,
          }}
          column
        >
          <Label
            value="Indisponível"
            style={{ color: Color.ECOMMERCE.WHITE, textAlign: "center" }}
            family="Bold"
            pointer
          />
        </Div>
      </Div>
    );
  }

  getQuantidadeLabel() {
    let quantidade = this.state.quantidade || 0;

    let labelQuantidade = `${quantidade} ${this.props.possuiQuantidadeFracionada ? "g" : "un"
      }`;

    return quantidade > 0 ? labelQuantidade : "0";
  }

  addQuantidadeItem(possuiQuantidadeFracionada, fatorEmGrama) {
    let quantidade = Number(this.state.quantidade);

    if (possuiQuantidadeFracionada && Utils.isValueValid(fatorEmGrama)) {
      quantidade = quantidade + Number(fatorEmGrama);
    } else {
      quantidade = quantidade + 1;
    }

    this.setState({ quantidade }, () =>
      this.props.handlerAddItemCarrinho(this.state.quantidade)
    );
  }

  removeQuantidadeItem(possuiQuantidadeFracionada, fatorEmGrama) {
    let quantidade = Number(this.state.quantidade);

    if (possuiQuantidadeFracionada && Utils.isValueValid(fatorEmGrama)) {
      quantidade = quantidade - Number(fatorEmGrama);
    } else {
      quantidade = quantidade - 1;
    }

    this.setState({ quantidade }, () =>
      this.props.handlerAddItemCarrinho(this.state.quantidade)
    );
  }

  thisUpdate(quantidade) {
    this.setState({ quantidade });
  }

  adicionarItem() {
    this.addQuantidadeItem(
      this.props.possuiQuantidadeFracionada,
      this.props.fatorEmGrama
    );
  }

  removerItem() {
    this.removeQuantidadeItem(
      this.props.possuiQuantidadeFracionada,
      this.props.fatorEmGrama
    );
  }

  render() {
    const {
      precoAtacado,
      precoUnitario,
      precoVenda,
      descricao,
      codigoBarras,
      disponivel,
      handlerClick,
      fatorEmGrama,
      percentualEconomia,
      quantidadeMinimaAtacado,
      possuiQuantidadeFracionada,
      precosConcorrentes,
      empresa,
      proibidaVenda,
    } = this.props;

    let corPrimaria = EmpresaUtils.getCorPrimaria(empresa);
    let exibePrecoUsuarioAnonimo = EmpresaUtils.getParametro(this.props.empresa, 'exibePrecoUsuarioAnonimo')

    return (
      <Div
        style={{ width: getWidth(), margin: 5 }}
        rounded
        handleMouseEnter={() =>
          this.setState({ show: true }, () =>
            this.consultarProdutoCarrinho(codigoBarras)
          )
        }
        handleMouseLeave={() => this.setState({ show: false })}
      >
        <Div style={{ margin: 5 }} inline="center" padding="2" rounded>
          <If and value1={this.state.quantidade > 0}>
            <Label
              style={{
                position: "absolute",
                transform: `translateY(15px)`,
                backgroundColor: Color.EMPRESA.TERCIARIA,
                color: "white",
                padding: 2,
                width: 40,
                fontSize: 10,
                textAlign: "center",
                borderRadius: 5,
              }}
              value={this.getQuantidadeLabel()}
            />
          </If>
          <If and value1={!disponivel}>
            {this.renderProdutoIndisponivel()}
          </If>
          <LazyLoad>
            <img
              src={this.state.imagem}
              className="border-bottom"
              alt={`Imagem do produto ${descricao}`}
              onClick={() => handlerClick(this.thisUpdate)}
              style={{
                width: getWidth(),
                height: HEIGHT,
              }}
              onError={() =>
                this.setState({
                  imagem: ProdutoUtils.URL_IMAGEM_PRODUTO_DEFAULT,
                })
              }
            />
          </LazyLoad>

          <If and value1={!this.state.indisponivel} value2={!proibidaVenda}>
            <Div style={{ width: "100%" }} inline="between" marginTop="1">
              <I
                icon={Type.ICON.MINUS}
                style={{
                  color: corPrimaria,
                  fontSize: Utils.isViewTabletAll() ? 30 : 20,
                }}
                pointer
                handlerClick={() => {
                  if (this.state.quantidade > 0) {
                    if (this.usuarioEstaLogado()) {
                      this.removeQuantidadeItem(
                        possuiQuantidadeFracionada,
                        fatorEmGrama
                      );
                    } else {
                      this.renderViewLogin(this.removerItem, corPrimaria);
                    }
                  }
                }}
              />

              <I
                icon={Type.ICON.PLUS}
                style={{
                  color: corPrimaria,
                  fontSize: Utils.isViewTabletAll() ? 30 : 20,
                }}
                pointer
                handlerClick={() => {
                  if (this.usuarioEstaLogado()) {
                    if (this.isProdutoValido({ precoVenda })) {
                      this.addQuantidadeItem(
                        possuiQuantidadeFracionada,
                        fatorEmGrama
                      );
                    }
                  } else {
                    this.renderViewLogin(this.adicionarItem, corPrimaria);
                  }
                }}
              />
            </Div>
          </If>
        </Div>
        <Div>
          <If and value1={Utils.isValueValid(descricao)}>
            <Label
              value={Utils.capitalize(String(descricao).slice(0, 50))}
              style={styles.descricao}
              family="Light"
            />
          </If>
          <If and value1={!exibePrecoUsuarioAnonimo}>
            <Label
              value={Type.TEXTO.login}
              className="descricao-produto d-flex align-self-start"
              style={{ color: corPrimaria, ...styles.descricaoLogin }}
              family="Light"
              pointer
              handlerClick={() => this.renderViewLogin(() => { }, corPrimaria)}
            />
          </If>

          <If and value1={exibePrecoUsuarioAnonimo}>
            <Div className="d-flex flex-column align-self-start">
              <If and value1={Number(percentualEconomia) > 0}>
                <Label
                  value={Mask.formatValueBr(precoUnitario)}
                  style={styles.precoUnitario}
                  family="SemiBold"
                />
              </If>
              <If
                and
                value1={Utils.isValueValid(precoVenda)}
                value2={!proibidaVenda}
              >
                <Label
                  value={Mask.formatValueBr(precoVenda)}
                  style={
                    this.state.indisponivel
                      ? {
                        ...styles.precoVenda,
                        ...styles.precoVendaIndisponivel,
                        color: corPrimaria,
                      }
                      : { ...styles.precoVenda, color: corPrimaria }
                  }
                  family="SemiBold"
                />
              </If>
            </Div>
            <If
              and
              value1={Utils.isValueValid(precoAtacado)}
              value2={precoAtacado > 0}
            >
              <Label
                value={`${Mask.formatValueBr(
                  precoAtacado
                )} à partir de ${quantidadeMinimaAtacado} unidades`}
                style={styles.precoAtacado}
                family="SemiBold"
              />
            </If>
            {Utils.isValueValid(precosConcorrentes) && (
              <Div
                style={{
                  borderRadius: 10,
                  backgroundColor: "#f2f2f2",
                  paddingLeft: 3,
                  paddingRight: 3,
                  margin: 5,
                }}
                column
              >
                <Div>
                  {Array.from(precosConcorrentes).map((concorrente, index) => {
                    return (
                      <Div
                        inline="between"
                        key={index}
                        style={{
                          color: Color.ECOMMERCE.CINZA_ESCURO,
                          padding: 1,
                        }}
                      >
                        <Div inline="start">
                          <Image src={concorrente.urlFoto} width="15px" />
                          <Label
                            value={Mask.formatValueBr(concorrente.precoVenda)}
                            family="SemiBold"
                            marginLeft="1"
                            marginTop="3"
                            style={{
                              fontSize: 8,
                            }}
                          />
                        </Div>
                      </Div>
                    );
                  })}
                  <Div
                    style={{
                      color: "#31CDFF",
                    }}
                    column
                  >
                    <Label
                      value="Economize"
                      family="SemiBold"
                      style={{ color: "#31CDFF", fontSize: 12 }}
                    />

                    <Label
                      value={`${Array.from(precosConcorrentes)[0].percentualEconomia
                        }%`}
                      style={{ fontSize: 15 }}
                      family="Bold"
                    />
                  </Div>
                </Div>
              </Div>
            )}
          </If>
        </Div>
      </Div>
    );
  }
}

export default ItemProdutoAbaixouPrecoMobile;
