import React, { Component } from "react";
import { Div, If, Label, NavbarTab, Row } from "../../../components";
import { Color } from "../../../utilities";

class ProdutoTabsDetalhes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemActive: {
        target: "DETALHES",
        value: "DETALHES",
        active: true,
      },
    };
  }

  getItens = () => {
    let item = this.state.itemActive;

    let infoAbas = [
      {
        target: "INFORMACOES",
        value: "INFORMAÇÕES GERAIS",
        active: item.value === "INFORMACOES",
      },
      {
        target: "DETALHES",
        value: "DETALHES DO PRODUTO",
        active: item.value === "DETALHES",
      },
    ];

    let detalhesAbas = [
      {
        target: "DETALHES",
        value: "DETALHES DO PRODUTO",
        active: item.value === "DETALHES",
      },
    ];

    return this.props.info ? infoAbas : detalhesAbas;
  };

  validNavAbas(itemActive) {
    // itemActive.stopPropagation();
    // itemActive.preventDefault();
    let item = this.state.itemActive;
    let formValidar = this.validaFormProd();

    if (formValidar) {
      this.setState({ itemActive });
    }

    if (!formValidar) {
      item.value = "INFORMACOES";
      item.target = "INFORMAÇÕES GERAIS";
    }
  }

  validaFormProd() {
    var valida = true;

    // if (!this.state.tipo) {
    //   Notification.danger("Selecione um tipo de Pesquisa");
    //   return false;
    // }

    // if (this.state.descricao === "") {
    //   Notification.danger("Escreve uma descrição para pesquisa");
    //   return false;
    // }

    // if (!this.state.datavalidade) {
    //   Notification.danger("Selecione uma data de validade");
    //   return false;
    // }

    return valida;
  }

  render() {
    let { target } = this.state.itemActive;

    console.log("produto", this.props.produto);

    return (
      <Div
        margin="3"
        padding="3"
        style={{
          width: "100%",
        }}
      >
        <NavbarTab
          styleItem={{
            fontSize: 13,
            color: "#c4c4c4",
            fontWeight: "bold",
          }}
          customStyles={{
            width: "100%",
          }}
          itens={this.getItens()}
          handlerClick={(itemActive) => {
            this.validNavAbas(itemActive);
          }}
        />

        <If and value1={target === "INFORMACOES"}>
          <Div margin="3" padding="3">
            {this.props.infoText}
          </Div>
        </If>

        <If and value1={target === "DETALHES"}>
          <Div paddingTop="2" marginTop="2">
            {this.props.produto.detalhes && (
              <Row col="12" padding="2">
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.produto.detalhes,
                  }}
                />
              </Row>
            )}
            <Row col="12" padding="0">
              <table
                style={{
                  width: "100%",
                  textAlign: "left",
                  borderCollapse: "collapse",
                }}
                bo
              >
                <tbody>
                  {this.props.detalhes.map((item, index) => (
                    <tr key={index} style={{ display: "block", marginBottom: 15 }}>

                      <td
                        style={{
                          //  display: "block",
                          fontWeight: "bold",
                          color: Color.EMPRESA.PRIMARIA,
                          borderRight: 'none',
                          borderRadius: "5px",
                          backgroundColor: "rgb(244, 244, 244)",
                          marginRight: 10,
                          width: "250px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          border: "1px solid #e2e2e2",
                        }}
                      >
                        <Label
                          value={item.nome}
                          style={{
                            fontWeight: "bold",
                            padding: "10px",

                            fontSize: "16px",
                            color: Color.EMPRESA.PRIMARIA,
                          }}
                        />
                      </td>
                      <td
                        style={{
                          border: "1px solid #e2e2e2",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: Color.ECOMMERCE.CINZA,
                          width: "78%"
                        }}
                      >
                        <div style={{ padding: "20px" }}>

                          {item.valor}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Row>
          </Div>
        </If>
      </Div>
    );
  }
}

export default ProdutoTabsDetalhes;
