import React, { Component } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import {
  Div,
  Label,
  ModalCustom,
  Row,
  SelectValue,
  Table,
  Title,
} from "../../components";
import AssinaturaService from "../../service/AssinaturaService";
import { Color, DateUtils, Type } from "../../utilities";
import CarrinhoAssinatura from "./CarrinhoAssinatura";
import TemplateFilterAssinaturas from "./TemplateFilterAssinaturas";

class ListaCompraListar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectPosicao: [
        new SelectValue(Type.POSICAO.TODAS, "Todos", true),
        new SelectValue(Type.POSICAO.ATIVA, "Ativas", false),
        new SelectValue(Type.POSICAO.CANCELADA, "Cancelada", false),
        new SelectValue(Type.POSICAO.PAUSADA, "Pausadas", false),
      ],
      selectFormaPagamento: [
        new SelectValue(null, "Todos", true),
        new SelectValue(Type.PAGAMENTO.DINHEIRO, "Dinheiro", false),
        new SelectValue(Type.PAGAMENTO.CREDITO, "Crédito", false),
        new SelectValue(Type.PAGAMENTO.DEBITO, "Débito", false),
      ],
      selectPagamento: [
        new SelectValue(null, "Todos", true),
        new SelectValue(Type.PAGAMENTO.ONLINE, "Pagamento Online", false),
        new SelectValue(
          Type.PAGAMENTO.OFFLINE,
          "Pagamento na Entrega/Retirada",
          false
        ),
      ],
      selectEntrega: [
        new SelectValue(null, "Todos", true),
        new SelectValue(Type.OPCOES_ENTREGA.ENTREGA, "Entrega", false),
        new SelectValue(Type.OPCOES_ENTREGA.RETIRADA, "Retirada", false),
      ],
      selectTipoFrequencia: [
        new SelectValue(null, "Todos", true),
        new SelectValue(Type.ASSINATURA.MENSAL, "Mensal", false),
        new SelectValue(Type.ASSINATURA.QUINZENAL, "Quinzenal", false),
        new SelectValue(Type.ASSINATURA.SEMANAL, "Semanal", false),
      ],
      selectPedidos: [
        new SelectValue(null, "Todos", true),
        new SelectValue("PENDENTES", "Pendentes", false),
        new SelectValue("CONCLUIDOS", "Concluidos", false),
      ],
      pedidoConsulta: null,
      assinaturasLista: [
        {
          codigo: "348349",
          descricao: "Nome da lista",
          cliente: "Client Nome Completo",
          produtoQtd: 42,
          data: "04-04-2022",
          assinatura: "Semanal",
        },
      ],
      cliente: "",
      closeModal: false,
      filtroPedidosConcluidos: false,
      filtroPedidosPendentes: true,
      dataFiltroInicial: "2022-04-01",
      dataFiltroFinal: "2022-06-15",
      assinaturas: [],
      pedidosPendentes: false,
      pedidosConcluidos: false,
      pausados: false,
      dashboard: {
        quantidadeAssinatura: "0",
        quantidadeAssinaturaAtiva: "0",
        quantidadeAssinaturaPausada: "0",
        quantidadeAssinaturaCancelada: "0",
        quantidadeAssinaturaMensal: "0",
        quantidadeAssinaturaQuinzenal: 0,
        quantidadeAssinaturaSemanal: "0",
        quantidadeClienteAssinados: "0",
        valorTotalAssinatura: "0,00",
        valorTotalAssinaturaMensal: "0,00",
        valorTotalAssinaturaQuinzenal: "0,00",
        valorTotalAssinaturaSemanal: "0,00",
        valorTotalAssinaturaCancelada: 0,
        ticketMedioTotalAssinatura: "0,00",
        ticketMedioTotalAssinaturaMensal: "0,00",
        ticketMedioTotalAssinaturaQuinzenal: "0,00",
        ticketMedioTotalAssinaturaSemanal: "0,00",
        valorTotalPedidoConcluidos: "0,00",
        quantidadeTotalPedidoConcluidos: "0",
        valorTotalPedidoConcluidosProdutos: "0,00",
        valorTotalPedidoConcluidosTaxas: "0,00",
        ticketMedioPedidoConcluidosProdutos: "0,00",
        ticketMedioPedidoConcluidosTaxas: "0,00",
        ticketMedioPedidoConcluidos: "0,00",
      },
      filtro: {
        posicao: Type.POSICAO.TODAS,
        tipoEntrega: null,
        tipo: null,
        tipoPagamento: null,
        tipoFormaPagamento: null,
        dataFiltroInicial: this.dataMensalAtual(1),
        dataFiltroFinal: this.dataMensalAtual(30),
        filtroPedidosConcluidos: false,
        filtroPedidosPendentes: false,
      },
    };
  }

  componentDidMount() {
    let { filtro } = this.state;
    this.consultarAssinatura(filtro);
    this.getDashboard(filtro.dataFiltroInicial, filtro.dataFiltroFinal);
  }

  addDiasDataHojeBr(days) {
    // Date.prototype.addDays = function (days) {
    //   const date = new Date(this.valueOf());
    //   date.setDate(date.getDate() + days);
    //   return date;
    // };

    let date = new Date();
    let newDate = DateUtils.addDays(date, days);
    return moment(newDate).format("YYYY-MM-DD");
  }

  dataMensalAtual(day) {
    const date = new Date();

    if (date.getMonth() === 1) {
      return moment(
        new Date(date.getFullYear(), date.getMonth(), day > 28 ? 28 : day)
      ).format("YYYY-MM-DD");
    }

    return moment(new Date(date.getFullYear(), date.getMonth(), day)).format(
      "YYYY-MM-DD"
    );
  }

  handleChangeInput = (name, value) => {
    let newFiltro = {
      ...this.state.filtro,
      [name]: value === "Todos" ? null : value,
    };

    this.setState(
      {
        filtro: newFiltro,
      },
      this.consultarAssinatura(newFiltro)
    );

    //this.getDashboard(newFiltro.dataFiltroInicial, newFiltro.dataFiltroFinal)
  };

  editarAssinatura(assinatura) {
    // this.props.history.push({
    //   pathname: "/admin/assinaturas/editar",
    //   state: { assinatura },
    // });
  }

  getDashboard(dataInicial, dataFinal) {
    let parametros = {
      dataFiltroInicial: dataInicial,
      dataFiltroFinal: dataFinal,
    };

    AssinaturaService.adminDashboardAssinatura(parametros).then(
      ({ status, data }) => {
        console.log(data);
        if (status) {
          this.setState({
            dashboard: data,
          });
        } else {
          this.setState({
            dashboard: [],
          });
        }
      }
    );
  }

  consultarAssinatura(filtro) {
    AssinaturaService.adminListarAssinaturas(filtro).then(
      ({ status, data }) => {
        if (status) {
          this.setState({
            assinaturas: data,
          });
        }
      }
    );
  }

  setCloseModal = (boolean) => {
    this.setState({
      closeModal: boolean,
    });
  };

  handlePedidos = (value) => {
    let newFiltro = {
      ...this.state.filtro,
      filtroPedidosConcluidos: value === "CONCLUIDOS" ? true : false,
      filtroPedidosPendentes: value === "PENDENTES" ? true : false,
    };

    this.setState(
      {
        filtro: newFiltro,
        pedidoConsulta: value,
      },
      this.consultarAssinatura(newFiltro)
    );
  };

  renderFiltros() {
    const {
      filtro,
      selectFormaPagamento,
      selectPagamento,
      selectTipoFrequencia,
      selectPedidos,
      selectEntrega,
      selectPosicao,
      pedidoConsulta,
    } = this.state;

    return (
      <TemplateFilterAssinaturas
        handleChangeInput={this.handleChangeInput}
        tipoSelecionado={filtro.tipo}
        dataInicial={filtro.dataFiltroInicial}
        dataExpiracao={filtro.dataFiltroFinal}
        selectTipoFrequencia={selectTipoFrequencia}
        selectFormaPagamento={selectFormaPagamento}
        selectPagamento={selectPagamento}
        selectEntrega={selectEntrega}
        selectPosicao={selectPosicao}
        posicao={filtro.posicao}
        tipoFormaPagamento={filtro.tipoFormaPagamento}
        tipoPagamento={filtro.tipoPagamento}
        tipoEntrega={filtro.tipoEntrega}
        selectPedidos={selectPedidos}
        pedidoConsulta={pedidoConsulta}
        handlePedidos={this.handlePedidos}
      />
    );
  }

  carrinhoAssinatura(rede) {
    this.setState({
      closeModal: true,
    });
  }

  render() {
    const { assinaturas, dashboard, filtro } = this.state;

    return (
      <>
        <ModalCustom
          title="Produtos da Lista"
          showModal={this.state.closeModal}
          setShowModal={this.setCloseModal}
          confirm={() => {}}
        >
          <CarrinhoAssinatura />
        </ModalCustom>
        <Div
          padding="2"
          paddingRight="5"
          marginLeft="3"
          bg={Color.NODE.WHITE}
          col="12"
          shadow
          className="border-left-primary"
        >
          <Row col="12" padding="3">
            <Title value="Lista de Compras" type="h4" family="Bold" />
          </Row>

          <Div col="12" paddingRight="0">
            <Table
              columns={[
                { value: "Referência", align: "center" },
                { value: "Cliente", align: "center" },
                { value: "Tipo", align: "center" },
                { value: "Tipo de Entrega", align: "center" },
                { value: "Assinante Desde", align: "center" },
                { value: "Assinatura", align: "center" },
                { value: "Últ. Pedido", align: "center" },
                { value: "Próx. Pedido", align: "center" },
                { value: "Qdt. Pedidos", align: "center" },
                { value: "Vlr. Últ. Pedido", align: "center" },
                { value: "St. Pedido", align: "center" },
              ]}
              fields={[
                {
                  value: "referencia",
                  type: Type.DADO.DATE,
                },
                {
                  value: "nomeCliente",
                  type: Type.DADO.STRING,
                },
                {
                  value: "tipo",
                  type: Type.DADO.STRING,
                },
                {
                  value: "tipoEntrega",
                  type: Type.DADO.STRING,
                },
                {
                  value: "dataCadastro",
                  type: Type.DADO.DATE,
                },
                {
                  value: "posicao",
                  type: Type.DADO.STRING,
                },
                {
                  value: "dataUltimoPedido",
                  type: Type.DADO.DATE,
                },
                {
                  value: "dataProximoPedido",
                  type: Type.DADO.DATE,
                },
                {
                  value: "quantidadePedidoGeradosFiltro",
                  value2: "quantidadePedidoGerados",
                  type: Type.DADO.VALUE,
                },
                {
                  value: "valorTotalAssinatura",
                  type: Type.DADO.STRING,
                },
                {
                  msgError: true,
                  value: "erroPedido",
                  error: "mensagemPedido",
                },
              ]}
              bordered
              //search
              orientation="center"
              rota="/admin/lista-compras/novo"
              edit
              sizeEdit="2"
              //   filter={this.renderFiltros()}
              handlerEdit={(rede) => this.editarAssinatura(rede)}
              handlerDetail={(rede) => this.carrinhoAssinatura()}
              detail
              iconDeDetail={Type.ICON.SALE_CART}
              data={assinaturas}
              error="Nenhuma Assinatura encontrada!"
            ></Table>
          </Div>
        </Div>
      </>
    );
  }
}

export default withRouter(ListaCompraListar);
