import React, { Component } from "react";
import {
  Div,
  Image,
  Input,
  InputDefault,
  Label,
  ModalNotification,
  Row,
} from "../../components";

import { Color, Http, Utils } from "../../utilities";
import BotaoPaginaInicial from "../utils/BotaoPaginaInicial";

const styles = {
  input: {
    width: "100%",
    border: "none",
    fontSize: 25,
    textAlign: "center",
    backgroundColor: "transparent",
    marginTop: 30,
    borderBottom: "1px solid #C4C4C4",
  },
  inputCartao: {
    width: "100%",
    paddingLeft: "20px",
    paddingBottom: "10px",
    borderRadius: "8px",
  },

  labelConfirmar: {
    backgroundColor: "#f2f2f2",
    color: Color.ECOMMERCE.CINZA,
    fontSize: 25,
    borderRadius: 10,
    padding: 10,
    marginTop: 10,
    width: "100%",
    textAlign: "center",
  },
  labelResetarSenha: {
    fontSize: 25,
    color: Color.ECOMMERCE.LARANJA,
  },
  labelInfoResetar: {
    fontSize: 15,
    color: Color.ECOMMERCE.LARANJA_CLARO,
  },
};

class ResetarSenha extends Component {
  constructor(props) {
    super(props);

    let path = String(props.location.pathname).split("/");

    this.state = {
      token: path[2],
      senha: "",
      senhaConfirmacao: "",
    };

    this.handlerChange = this.handlerChange.bind(this);
  }

  componentDidMount() {
    if (!this.state.token) {
      this.props.history.replace("/"); // Redireciona para a página inicial ou outra de sua escolha
      return;
    }

    this.props.history.replace("/resetarsenha");
    this.consultarEmpresa();
  }

  handlerChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  consultarEmpresa() {
    Http.get("empresa")
      .then(({ status, data }) => {
        if (status) {
          this.setState({ empresa: data });
        }
      })
      .catch((erro) => console.log(erro));
  }

  validarSenhas() {
    let erro = "";

    if (String(this.state.senha) !== String(this.state.senhaConfirmacao)) {
      erro = "As senhas estão diferentes, por favor tente novamente!";
    }

    if (
      String(this.state.senha).length === 0 ||
      String(this.state.senhaConfirmacao).length === 0
    ) {
      erro = "Preencher os campos senha e senha de confirmação!";
    }

    if (Utils.isValueValid(erro)) {
      ModalNotification.renderMessage({
        title: "Mensagem",
        body: (
          <Label
            value={erro}
            style={{ fontSize: 25, textAlign: "center" }}
            family="Light"
          />
        ),
        handlerConfirm: null,
        handlerCancel: () => console.log("CANCELADO"),
        labelButtonCancel: "Fechar",
        responsive: "5",
      });
    }

    return !Utils.isValueValid(erro);
  }

  atualizarSenhaCliente() {
    Http.put("/cliente/atualizarsenhacliente", {
      senha: this.state.senha,
      chaveResetSenha: this.state.token,
    })
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            ModalNotification.renderMessage({
              title: "Mensagem",
              body: (
                <Label
                  value={data.mensagem}
                  style={{ fontSize: 25, textAlign: "center" }}
                  family="Light"
                />
              ),
              handlerConfirm: null,
              handlerCancel: () => this.props.history.replace("/"),
              labelButtonCancel: "Fechar",
              responsive: "5",
            });
          } else {
            ModalNotification.renderMessage({
              title: "Mensagem",
              body: (
                <Label
                  value={data.mensagem}
                  style={{ fontSize: 25, textAlign: "center" }}
                  family="Light"
                />
              ),
              handlerConfirm: null,
              handlerCancel: () => {},
              labelButtonCancel: "Fechar",
              responsive: "5",
            });
          }
        }
      })
      .catch((erro) => console.log(erro));
  }

  render() {
    const { senha, senhaConfirmacao } = this.state;
    let empresa = this.state.empresa || {};
    let parametros = empresa.parametros || {};

    let senhasPreenchidas =
      Utils.isValueValid(senha) && Utils.isValueValid(senhaConfirmacao);

    return (
      <>
        <Image
          src={parametros.urlFoto}
          styleImage={{ position: "absolute", top: 15, left: 15, width: 250 }}
          handlerClick={() => this.props.history.replace("/")}
        />
        <Div responsive="6">
          <Div className="d-flex flex-column">
            <Row
              col="12"
              style={{
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                fontSize: "18px",
                padding: "15px 20px",
                border: "1px solid #dee2e6",
                borderBottom: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              family="Bold"
            >
              <Div responsive="4" padding="0">
                <Label
                  value="Resetar a Senha"
                  style={{
                    color: Color.ECOMMERCE.LARANJA,
                    textTransform: "uppercase",
                    width: "100%",
                    marginLeft: "5px",
                  }}
                  family="SemiBold"
                  marginBottom="0"
                />
              </Div>
            </Row>
          </Div>
          <Div col="12" className="border" style={{ padding: "10px 20px" }}>
            <Label
              value="Informe abaixo a nova senha para a sua conta"
              style={styles.labelInfoResetar}
            />

            <InputDefault
              label="Senha: "
              name="senha"
              password
              value={senha}
              handlerChange={this.handlerChange}
              autoComplete="off"
              placeholder="Escreva sua senha"
              styleContainer={{ marginBottom: "10" }}
              style={styles.inputCartao}
            />

            <InputDefault
              label="Confirmar Senha: "
              password
              name="senhaConfirmacao"
              value={senhaConfirmacao}
              handlerChange={this.handlerChange}
              autoComplete="off"
              placeholder="Confirme sua senha"
              styleContainer={{ marginBottom: "10" }}
              style={styles.inputCartao}
            />
            {/* <Input
              handlerChange={this.handlerChange}
              maxLength="10"
              name="senha"
              value={senha}
              placeholder="Senha"
              type="password"
              style={styles.input}
            />
            <Input
              handlerChange={this.handlerChange}
              maxLength="10"
              name="senhaConfirmacao"
              value={senhaConfirmacao}
              placeholder="Confirmar senha"
              type="password"
              style={styles.input}
            /> */}
            <Label
              value="Confirmar alteração de senha"
              family="SemiBold"
              style={
                senhasPreenchidas
                  ? {
                      ...styles.labelConfirmar,
                      backgroundColor: parametros.corPrimaria,
                      color: "white",
                    }
                  : styles.labelConfirmar
              }
              pointer
              handlerClick={() => {
                if (this.validarSenhas()) {
                  this.atualizarSenhaCliente();
                }
              }}
            />

            <BotaoPaginaInicial history={this.props.history} />
          </Div>
        </Div>
      </>
    );
  }
}

export default ResetarSenha;
