import React, { useState } from "react";
import { Color, Mask, Type, Utils } from "../../../utilities";
import { Div, I, If, InputDefault, Label, Row } from "../../../components";

const styles = {
  input: {
    width: "100%",
    border: "none",
    textAlign: "center",
    height: 50,
    backgroundColor: "transparent",
    borderBottom: "1px solid #C4C4C4",
  },
  inputCustom: {
    paddingLeft: "20px",
    paddingBottom: "10px",
    borderRadius: "8px",
  },
  inputCustomForm: {
    // paddingLeft: "20px",
    paddingBottom: "10px",
    borderRadius: "8px",
  },
  input50: {
    width: "50%",
    border: "none",
    textAlign: "center",
    backgroundColor: "transparent",
    marginTop: 30,
    borderBottom: "1px solid #C4C4C4",
  },
  input50Disabled: {
    width: "50%",
    border: "none",
    textAlign: "center",
    marginTop: 30,
    borderBottom: "1px solid #C4C4C4",
    cursor: "no-drop",
  },
  containerContinuar: {
    backgroundColor: Color.ECOMMERCE.CINZA,
    color: "white",
    padding: 12,
  },
  containerContinuarActive: {
    backgroundColor: Color.EMPRESA.PRIMARIA,
    color: "white",
    padding: 12,
  },
  containerBottom: {
    //   position: "fixed",
    bottom: 0,
    zIndex: 1002,
    width: "100%",
  },
  containerCheckBox: {
    backgroundColor: "#f2f2f2",
    padding: 20,
    borderRadius: 10,
    marginTop: 15,
  },
  labelDescricaoActive: {
    backgroundColor: Color.ECOMMERCE.LARANJA,
    color: Color.ECOMMERCE.WHITE,
    padding: 5,
    margin: 5,
    borderRadius: 10,
    width: 100,
    textAlign: "center",
  },
  labelDescricao: {
    color: Color.ECOMMERCE.CINZA_ESCURO,
    padding: 5,
    margin: 5,
    borderRadius: 10,
    width: 100,
    textAlign: "center",
  },
};

export default function RenderNovoCliente() {
  const [cliente, setCliente] = useState({
    nome: "",
    email: "",
    telefone: "",
  });

  function handlerChange(e) {
    const { value, name } = e.target;

    setCliente({
      ...cliente,
      [name]: value,
    });
  }
  return (
    <Div
      className="scrollhost"
      style={{
        width: "100%",
        height: "100%",
        padding: 10,
      }}
    >
      <Div padding="2">
        <Row
          padding="3"
          col="12"
          className="border"
          style={{ padding: "10px 20px" }}
        >
          <InputDefault
            //   id="inputNomeCompletoCadastroCliente"
            handlerChange={handlerChange}
            // handlerBlur={this.handlerBlur}
            name="nome"
            label="Nome Completo"
            value={cliente.nome}
            placeholder="Nome completo"
            style={styles.inputCustom}
            //  error={cliente.nomeError}
            responsive="6"
            styleContainer={{ marginBottom: "10" }}
          />

          <InputDefault
            responsive="6"
            handlerChange={handlerChange}
            // handlerBlur={(e) => {
            //   if (Mask.validarEmail(e.target.value)) {
            //     this.handlerBlur(e);
            //   } else {
            //     this.handlerBlur({
            //       target: { value: null, name: e.target.name },
            //     });
            //   }
            // }}
            name="email"
            label="Email"
            value={cliente.email}
            placeholder="Email"
            //  error={cliente.emailError}
            style={styles.inputCustom}
          />
          <InputDefault
            responsive="6"
            Label="Telefone"
            handlerChange={(e) => {
              Mask.maskPhone(e);
              handlerChange(e);
            }}
            //   handlerBlur={this.handlerBlur}
            name="telefone"
            value={cliente.telefone}
            placeholder="Telefone"
            label="Telefone"
            //   error={cliente.telefoneError}
            style={styles.inputCustom}
          />
        </Row>
        <Div marginTop="5"></Div>

        {/* <Div style={styles.containerBottom}>
          <Div
            responsive="12"
            inline="center"
            style={styles.containerContinuarActive}
            pointer
            handlerClick={() => {}}
          >
            <Label value={"Enviar"} family="SemiBold" />
            <I
              icon={Type.ICON.ARROW_CHEV_RIGHT}
              marginLeft="2"
              marginBottom="2"
            />
          </Div>
        </Div> */}
      </Div>
    </Div>
  );
}
