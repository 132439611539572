import React, { Component } from "react";
import {
  Div,
  Table,
  InputSearch,
  Title,
  Checkbox,
  If,
  ButtonIconOutLine,
  Notification,
  ModalNotification,
  Label,
  Select,
  SelectValue,
  Row,
} from "../../components";
import { connect } from "react-redux";
import { Color, Http, Type, Utils } from "../../utilities";
import "./produtosDetalhe.css";
import ProdutoCataloService from "../../service/ProdutoCatalogoService";
import SelectReact from "react-select";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

const ENTER = 13;

const styles = {
  divisor: {
    color: Color.RGBA.CINZA,
    margin: "0px 10px",
    fontSize: "16px",
  },
  labelFiltro: {
    color: Color.CUSTOM_COLORS.PRIMARY,
    fontSize: "16px",
    fontWeight: "600",
    cursor: "pointer",
  },
  labelFiltroActive: {
    color: "#000",
    fontSize: "16px",
    fontWeight: "600",
  },
  labelNumero: {
    color: Color.RGBA.CINZA,
    marginLeft: "5px",
    fontSize: "16px",
  },
};

class ProdutosDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produtos: [],
      produtosSlice: [],
      produtosSelecionados: [],
      produtosSemPreco: [],
      departamentosSelecionados: [],
      secaoSelect: [],
      objSecao: {},
      tipos: [
        new SelectValue("", "Todos", true),
        new SelectValue("SIM", "Sim", false),
        new SelectValue("NAO", "Não", false),
      ],
      situacaoSelect: [
        new SelectValue(0, "Selecione um opção", true),
        new SelectValue(Type.CATALOGO.BLOQUEADOS, "BLOQUEADOS", false),
        new SelectValue(Type.CATALOGO.DISPONIVEL, "PUBLICADOS", false),
      ],
      filtrosDepartamentos: {
        departamentos: [new SelectValue(0, "Selecione um Departamento", true)],
        secoes: [],
      },
      situacaoValue: 0,
      departamentos: [],
      secoes: [],
      idDepartamento: "",
      idSecao: "",
      selectNormalizado: "",
      selecionarTodos: false,
      buscaCatalogo: Type.CATALOGO.TODOS,
      filtros: {
        idEmpresa: props.idEmpresa,
        idFilial: props.idFilial,
        ativo: true,
        limite: 200,
        descricaoProduto: null,
        origemWeb: "MOBIOH",
        dataUltimaEntrada: null,
        departamentos: null,
        exportado: false,
      },
      quantidadeBloqueado: 0,
      quantidadePendente: 0,
      quantidadePublicado: 0,
      quantidadeTotal: 0,
      numeroPagina: 1,
      descricaoProduto: "",
      departamentosFiltro: [],
      secoesFiltro: [],
      catalogo: [],
      multiSelectSec: [],
      depSelecionado: 0,
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {
    //this.consultarProdutos();
    this.getResumoProdutos();
    this.getProdutosCatalogo(1);
    this.listarDepartamentos();
  }

  componentDidUpdate() {
    if (this.state.loading) {
      this.getResumoProdutos();
      this.getProdutosCatalogo(1);
    }
  }

  filtroProdutos(numeroPagina = 1, catalogo = this.state.buscaCatalogo) {
    let busca = {
      situacao: catalogo,
      numeroPagina: numeroPagina,
    };

    return busca;
  }

  listarDepartamentos() {
    let filtros = { ...this.state.filtrosDepartamentos };
    filtros.origem = "MOBIOH";
    filtros.tipoClassificacao = "DEPARTAMENTO";

    ProdutoCataloService.getDepartamentoSecaoFiltro()
      .then((data) => {
        let newData = [];

        if (Utils.isNotNull(data)) {
          newData = data.sort(
            (a, b) =>
              Number.parseInt(a.idDepartamentoSecaoExterno) -
              Number.parseInt(b.idDepartamentoSecaoExterno)
          );
          let departamentos = [
            new SelectValue(0, "Selecione um Departamento", true),
          ];

          newData.forEach((depto) => {
            departamentos.push(
              new SelectValue(
                depto.idDepartamentoSecao,
                depto.idDepartamentoSecaoExterno + " - " + depto.descricao,
                false,
                depto.secoes
              )
            );
          });

          this.setState({
            filtrosDepartamentos: {
              departamentos,
            },
          });
        }
      })
      .catch((error) => console.log(error));
  }

  getSecoes(dep) {
    const options = [];
    let secao = [];

    this.state.filtrosDepartamentos.departamentos.forEach((item) => {
      if (item.id === Number.parseInt(dep)) {
        secao = item.obj;
      }
    });

    let newData = secao.sort(
      (a, b) =>
        Number.parseInt(a.idDepartamentoSecaoExterno) -
        Number.parseInt(b.idDepartamentoSecaoExterno)
    );

    newData.forEach((item) => {
      options.push({
        value: item.idDepartamentoSecao,
        label: item.idDepartamentoSecaoExterno + " - " + item.descricao,
      });
    });

    let obj = {
      numeroPagina: 1,
      departamentos: [
        {
          idFiltro: dep.toString(),
        },
      ],
    };

    if (dep === 0) {
      this.getProdutosCatalogo(1);
      this.setState({
        depSelecionado: dep,
        secaoSelect: [],
        multiSelectSec: [],
        objSecao: [],
      });
    } else {
      ProdutoCataloService.consultarProdutosFiltro(obj).then((data) => {
        if (data) {
          this.setState({
            catalogo: data,
            secaoSelect: options,
            objSecao: obj,
            depSelecionado: dep,
          });
        } else {
          this.setState({
            secaoSelect: options,
            objSecao: obj,
            depSelecionado: dep,
          });
        }
      });
    }
  }

  filtrarPorSecao(secoes) {
    const { objSecao } = this.state;

    let newObj = {
      ...objSecao,
      numeroPagina: 1,
      secoes: [],
    };

    secoes.forEach((item) => {
      newObj.secoes.push({ idFiltro: item.value.toString() });
    });

    ProdutoCataloService.consultarProdutosFiltro(newObj).then((data) => {
      if (data) {
        this.setState({
          catalogo: data,
          multiSelectSec: secoes,
          objSecao: newObj,
        });
      } else {
        Notification.danger("Falha ao buscar produtos por seção");
      }
    });
  }

  buscarProduto(busca) {
    let obj = {
      ...this.filtroProdutos(1, Type.CATALOGO.TODOS),
      ...this.state.objSecao,
      numeroPagina: 1,
    };

    obj["descricao"] = busca;

    if (Utils.isNotNull(busca)) {
      ProdutoCataloService.consultarProdutosFiltro(obj).then((data) => {
        if (data) {
          this.setState({
            catalogo: data,
          });
        }
      });
    }
  }

  getProdutosCatalogo(pag = 1, catalogo = Type.CATALOGO.TODOS) {
    let obj = this.filtroProdutos(pag, catalogo);

    ProdutoCataloService.consultarProdutosFiltro(obj).then((data) => {
      if (data) {
        this.setState({
          numeroPagina: pag,
          catalogo: data,
          loading: false,
          depSelecionado: 0,
          multiSelectSec: [],
        });
      }
    });
  }

  getResumoProdutos() {
    //let idFilial = this.props.idFilial;
    ProdutoCataloService.getResumoProdutos().then((data) => {
      if (data) {
        this.setState({
          quantidadeBloqueado: data.quantidadeBloqueado,
          quantidadePublicado: data.quantidadePublicado,
          quantidadeTotal: data.quantidadeTotal,
          loading: false,
        });
      }
    });
  }

  editarProduto(produto) {
    this.props.history.push({
      pathname: `/admin/produtos/${produto.codigoBarras}`,
      state: { produto, produtoDetalhes: produto, edit: true },
    });
  }

  async consultarProdutos() {
    let filtros = { ...this.state.filtros };

    let departamentos = this.state.departamentosSelecionados;

    departamentos.forEach((depto) => {
      depto.descricaoProduto = filtros.descricaoProduto;
      depto.dataUltimaEntrada = filtros.dataUltimaEntrada;
      depto.origemWeb = filtros.origemWeb;
      depto.limite = filtros.limite;
    });

    let url = Utils.isValueValid(departamentos)
      ? "/produto/listar/resumido/departamento"
      : "/produto/listar/resumido";

    Http.post(url, Utils.isValueValid(departamentos) ? departamentos : filtros)
      .then(({ status, data }) => {
        if (status) {
          Array.from(data).forEach((produto) => {
            produto.isProdutoDisponivel = produto.situacao === "DISPONIVEL";
          });
          this.setState({
            produtos: data,
            produtosSlice: data.length > 100 ? data.slice(0, 100) : data,
          });
        }
      })
      .catch((error) => console.log(error));
  }

  possuiProdutosSelecionados = () => {
    let produtosSelecionados = this.state.produtosSelecionados;
    let produtos = Utils.isValueValid(produtosSelecionados)
      ? produtosSelecionados.filter((p) => p.selected)
      : [];
    return {
      possuiProdutoSelecionado: Utils.isValueValid(produtos),
      quantidade: Utils.isValueValid(produtos) ? produtos.length : 0,
      qdtSemPreco: this.state.produtosSemPreco.length,
      selectTodos: this.state.selecionarTodos,
    };
  };

  handleScroll = (e) => {
    let element = e.target;
    const { numeroPagina, catalogo, buscaCatalogo, depSelecionado, objSecao } =
      this.state;
    objSecao.numeroPagina = numeroPagina + 1;

    let obj =
      depSelecionado === 0
        ? this.filtroProdutos(numeroPagina + 1, buscaCatalogo)
        : objSecao;

    if (element.scrollHeight - element.scrollTop - element.clientHeight <= 1) {
      ProdutoCataloService.consultarProdutosFiltro(obj).then((data) => {
        if (data) {
          this.setState({
            numeroPagina: Utils.isArrayNotEmpty(data)
              ? numeroPagina + 1
              : numeroPagina,
            catalogo: [...catalogo, ...data],
          });
        }
      });
    }

    if (element.scrollTop === 0 && catalogo.length > 100) {
      this.setState(
        {
          numeroPagina: 1,
          objSecao,
        },
        depSelecionado === 0
          ? this.getProdutosCatalogo(1)
          : console.log("departamento")
      );
    }
  };

  confirmarSituacao(obj) {
    ProdutoCataloService.alterarSituacao(obj).then(({ status, mensagem }) => {
      if (status) {
        Notification.success(mensagem);
        this.setState({
          produtosSelecionados: [],
          buscaCatalogo: Type.CATALOGO.TODOS,
          loading: true,
        });
      } else {
        Notification.danger(mensagem);
      }
    });
  }

  mudarSituacao() {
    const { produtosSelecionados, situacaoValue } = this.state;
    let obj = [];

    produtosSelecionados.forEach((item) => {
      obj.push({
        idProdutoWeb: item.idProdutoWeb,
        situacao: this.state.situacaoValue,
      });
    });

    if (situacaoValue !== 0 && situacaoValue !== "0") {
      ModalNotification.renderMessage({
        title: "Deseja Alterar a situação dos produtos selecionados? ",
        body: `Será alterado para situação ${this.state.situacaoValue}!`,
        handlerConfirm: () => this.confirmarSituacao(obj),
        handlerCancel: () => {},
        labelButtonCancel: "Cancelar",
      });
    }
  }

  render() {
    const {
      selecionarTodos,
      produtosSelecionados,
      buscaCatalogo,
      quantidadeBloqueado,
      quantidadeTotal,
      quantidadePublicado,
      catalogo,
    } = this.state;

    let dadosProdutosSelecionados = this.possuiProdutosSelecionados();

    return (
      <Div
        margin="3"
        shadow
        bg={Color.NODE.WHITE}
        borderLeft={Color.NODE.PRIMARY}
        style={{
          overflow: "hidden",
          // height: "82vh",
          overflowY: "auto",
        }}
        //handlerScroll={(e) => this.handleScroll(e)}
      >
        <Title
          value="Produtos"
          type="h4"
          paddingLeft="4"
          paddingRight="4"
          paddingTop="3"
        />

        <Div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: "15px",
            margin: "20px 15px 10px",
          }}
        >
          <Label
            style={
              buscaCatalogo === Type.CATALOGO.TODOS
                ? styles.labelFiltroActive
                : styles.labelFiltro
            }
            value="Todos"
            handlerClick={() =>
              this.setState(
                { buscaCatalogo: Type.CATALOGO.TODOS },
                this.getProdutosCatalogo(1, Type.CATALOGO.TODOS)
              )
            }
          />
          <Label style={styles.labelNumero} value={`(${quantidadeTotal})`} />
          <Label style={styles.divisor} value="|" />

          <Label
            style={
              buscaCatalogo === Type.CATALOGO.DISPONIVEL
                ? styles.labelFiltroActive
                : styles.labelFiltro
            }
            handlerClick={() =>
              this.setState(
                { buscaCatalogo: Type.CATALOGO.DISPONIVEL },
                this.getProdutosCatalogo(1, Type.CATALOGO.DISPONIVEL)
              )
            }
            value="Publicados"
          />
          <Label
            style={styles.labelNumero}
            value={`(${quantidadePublicado})`}
          />
          <Label style={styles.divisor} value="|" />

          <Label
            style={
              buscaCatalogo === Type.CATALOGO.BLOQUEADOS
                ? styles.labelFiltroActive
                : styles.labelFiltro
            }
            handlerClick={() =>
              this.setState(
                { buscaCatalogo: Type.CATALOGO.BLOQUEADOS },
                this.getProdutosCatalogo(1, Type.CATALOGO.BLOQUEADOS)
              )
            }
            value="Bloqueados"
          />
          <Label
            style={styles.labelNumero}
            value={`(${quantidadeBloqueado})`}
          />
        </Div>

        <Div col="12" inline="between">
          <Div responsive="6">
            <InputSearch
              // handlerChange={(e) =>
              //   this.buscarProduto(e.target.value)
              // }
              handlerBlur={(e) => this.buscarProduto(e.target.value)}
              handlerIcon={(e) => this.buscarProduto(e.target.value)}
              handlerKeyUp={(e) => {
                if (e.keyCode === ENTER) {
                  this.buscarProduto(e.target.value);
                }
              }}
              bgIcon={Color.NODE.BEGE}
              colorText={Color.NODE.WHITE}
              label="Pesquise por descrição, código de barras, código produto ou ncm"
            />
          </Div>
          <Div responsive="6" style={{ display: "flex" }} padding="0">
            <Select
              required
              responsive="6"
              label="Departamentos"
              valueSelected={this.state.depSelecionado}
              multi={true}
              handlerChange={(e) => this.getSecoes(e.target.value)}
              values={this.state.filtrosDepartamentos.departamentos}
            />
            <Div col="6">
              <Label value="Seções" />
              <SelectReact
                placeholder="Selecione Seções"
                isMulti={true}
                options={this.state.secaoSelect}
                value={this.state.multiSelectSec}
                onChange={(e) => this.filtrarPorSecao(e)}
                noOptionsMessage={() => "Nenhuma Seção Encontrada"}
              />
            </Div>
          </Div>
        </Div>

        <If and value1={dadosProdutosSelecionados.quantidade > 0}>
          <Div
            col="12"
            inline="between"
            marginTop="3"
            style={{ marginBottom: "-15px" }}
          >
            <Row col="6">
              <Select
                required
                responsive="6"
                label="Situação"
                paddingLeft="0"
                handlerChange={(e) =>
                  this.setState({ situacaoValue: e.target.value })
                }
                values={this.state.situacaoSelect}
                valueSelected={this.state.situacaoValue}
              />

              <ButtonIconOutLine
                value="Aplicar em Massa"
                style={{ maxWidth: "170px", height: "40px", marginTop: "27px" }}
                btn={Color.NODE.PRIMARY}
                handlerClick={() => this.mudarSituacao()}
                col="2"
                className="custom-btn"
              />
            </Row>
            <Row>
              <Label
                type="h4"
                style={{
                  fontSize: "17px",
                  margin: "35px 30px 10px 10px",
                  fontWeight: "600",
                }}
                value={`${dadosProdutosSelecionados.quantidade} produtos selecionados`}
              />
            </Row>
          </Div>
        </If>

        <Div
          padding="2"
          style={{
            maxHeight: "53vh",
            overflow: "scroll",
            paddingTop: "0px !important",
          }}
          handlerScroll={(e) => this.handleScroll(e)}
        >
          <Table
            //rota={"produtos/cadastrar"}
            columns={[
              { value: "Foto", align: "center" },
              //   { value: "Cód. Produto", align: "center" },
              { value: "Cód. Barras", align: "center" },
              { value: "Descrição", align: "left" },
              { value: "Departamento", align: "left" },
              { value: "Seção", align: "left" },
              { value: "Preço de Venda", align: "center" },
              { value: "Preço Atacado", align: "center" },
              { value: "Estoque", align: "center" },
              { value: "Situação", align: "center" },
            ]}
            fields={[
              {
                value: "urlImagemPrincipalThumbnail",
                type: Type.DADO.IMG,
                align: "left",
                col: 2,
              },
              // {
              //   value: "codigoProduto",
              //   type: Type.DADO.STRING,
              //   align: "center",
              //   col: 2,
              // },
              {
                value: "codigoBarras",
                type: Type.DADO.STRING,
                align: "center",
                col: 2,
              },
              {
                value: "descricao",
                type: Type.DADO.STRING,
                align: "left",
                col: 3,
              },
              {
                value: "descricaoDepartamento",
                type: Type.DADO.STRING,
                align: "left",
                col: 2,
              },
              {
                value: "descricaoSecao",
                type: Type.DADO.STRING,
                align: "left",
                col: 2,
              },
              {
                value: "precoVenda",
                type: Type.DADO.CURRENCY,
                align: "center",
                col: 1,
              },
              {
                value: "precoVendaAtacado",
                type: Type.DADO.CURRENCY,
                align: "center",
                col: 1,
              },
              {
                value: "quantidadeEstoqueAtual",
                type: Type.DADO.INTEGER,
                align: "center",
                col: 1,
              },
              {
                value: "situacao",
                type: Type.DADO.LABEL,
                align: "center",
                color: "#FFF",
              },
            ]}
            bordered
            orientation="center"
            data={catalogo}
            iconEdit={Type.ICON.EDIT}
            handlerEdit={(produto) => this.editarProduto(produto)}
            edit
            error="Nenhum produto encontrado"
            checkbox={{ nameChecked: "selected", valueChecked: true }}
            handlerCheckbox={(produto) => {
              if (Utils.isNotNull(produto)) {
                produto.selected = !produto.selected;
                let produtoEncontrado = produtosSelecionados.filter(
                  (p) => p.codigoBarras === produto.codigoBarras
                )[0];

                if (Utils.isValueValid(produtoEncontrado)) {
                  produtoEncontrado = produto;
                } else {
                  produtosSelecionados.push(produto);
                }
              } else {
                produto.selected = false;
              }
              this.setState({
                produtosSelecionados,
              });
            }}
            labelCheckBox={
              <Checkbox
                width="50px"
                label="Selecione Todos"
                checked={selecionarTodos}
                handlerChange={({ checked }) => {
                  let produtosList = [...catalogo];

                  let produtosListSemPreco = produtosList.filter(
                    (p) =>
                      p.precoUnitarioMask === "0,00" ||
                      Number(p.precoUnitario) === 0
                  );

                  if (Utils.isArrayNotEmpty(produtosList)) {
                    produtosList.forEach((p) => {
                      p.selected = checked;
                    });

                    this.setState({
                      produtosSelecionados: checked ? produtosList : [],
                      produtosSemPreco: checked ? produtosListSemPreco : [],
                      selecionarTodos: checked,
                    });
                  } else {
                    Notification.info("Nenhum produto selecionado");
                  }
                }}
              ></Checkbox>
            }
          ></Table>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  idEmpresa: store.loginState.user.idEmpresa,
  idFilial: store.loginState.user.idFilial,
});

export default withRouter(connect(mapStateToProps)(ProdutosDashboard));
