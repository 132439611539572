import React from "react";
import { Component } from "react";
import {
  Checkbox,
  ComboBox,
  Div,
  I,
  If,
  Input,
  InputCep,
  InputCpfCnpj,
  InputDefault,
  InputInteger,
  Label,
  ModalNotification,
  RadioCustom,
  Row,
  Select,
  SelectValue,
  Title,
} from "../../components";
import { Color, EndPoints, Http, Mask, Type, Utils } from "../../utilities";

import "./cliente.css";
import axios from "axios";
import UsuarioUtils from "../utils/UsuarioUtils";
import AdicionarAlterarEndereco from "./AdicionarAlterarEndereco";

const PATH_VIA_CEP = "https://viacep.com.br/ws/%s/json";

const styles = {
  containerMenu: {
    borderRadius: 10,
    height: "100%",
  },
  inputCartao: {
    paddingLeft: "20px",
    paddingBottom: "10px",
    borderRadius: "8px",
  },
  meusDados: {
    color: Color.EMPRESA.PRIMARIA,
    backgroundColor: Color.ECOMMERCE.WHITE,
    borderRadius: 5,
    padding: 5,
    fontSize: 20,
    width: "100%",
    borderBottom: "1px solid #F2F2F2",
  },
  meusDadosActive: {
    color: Color.ECOMMERCE.WHITE,
    backgroundColor: Color.EMPRESA.PRIMARIA,
    borderRadius: 5,
    padding: 5,
    fontSize: 20,
    width: "100%",
    borderBottom: "1px solid #F2F2F2",
  },
  btnAddEndereco: {
    color: Color.ECOMMERCE.WHITE,
    backgroundColor: Color.RGBA.CINZA,
    borderRadius: 5,
    padding: 5,
    fontSize: 20,
    width: "100%",
    borderBottom: "1px solid #F2F2F2",
  },
  input: {
    width: "100%",
    border: "none",
    textAlign: "center",
    backgroundColor: "transparent",
    borderBottom: "1px solid #C4C4C4",
  },
  input50: {
    width: "50%",
    border: "none",
    textAlign: "center",
    backgroundColor: "transparent",
    borderBottom: "1px solid #C4C4C4",
  },
  input50Disabled: {
    width: "50%",
    border: "none",
    textAlign: "center",
    borderBottom: "1px solid #C4C4C4",
    cursor: "no-drop",
  },
  label: { fontSize: 20, color: Color.EMPRESA.PRIMARIA, marginTop: 20 },
  btnAlterarDadosPessoais: {
    fontSize: 20,
    color: Color.ECOMMERCE.WHITE,
    backgroundColor: Color.ECOMMERCE.LARANJA,
    borderRadius: 5,
    padding: 15,
    marginTop: 20,
    width: "50%",
    textAlign: "center",
  },
  enderecoSelecionado: {
    borderBottom: "1px solid #f2f2f2",
    color: Color.EMPRESA.TERCIARIA,
    height: 70,
  },
  labelTitle: {
    width: "100%",
    fontSize: 25,
    color: Color.EMPRESA.TERCIARIA,
  },
  labelPrincipal: {
    color: Color.ECOMMERCE.WHITE,
    backgroundColor: Color.EMPRESA.TERCIARIA,
    padding: 8,
    borderRadius: 5,
  },
  containerBotaoCancelar: {
    color: "#878787",
    padding: 15,
    margin: 10,
    position: "absolute",
    right: 10,
    top: 10,
  },
  containerCheckBox: {
    backgroundColor: "#f2f2f2",
    padding: 20,
    borderRadius: 10,
    marginTop: 15,
  },
};

class DadosPessoaisCliente extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mostrarEndereco: false,
      mostrarDadosPessoais: true,
      mostrarAlteracaoSenha: false,
      mostrarAdicionarNovoEndereco: false,
      cliente: this.getCliente(),
      emailAlteracaoSenha: null,
      enderecoAlterado: { descricao: "Casa", cep: "" },
      adicionarEndereco: false,
      enderecoSelecionado: {},
      labelEnderecos: "Adicionar novo endereço",
      labelMensagemCepNaoEncontrado: "",
      pedido: {
        referencia: "",
      },
      ...props.location.state,
    };

    this.handlerChange = this.handlerChange.bind(this);
    this.cadastrar = this.cadastrar.bind(this);
    this.atualizarDados = this.atualizarDados.bind(this);
  }

  consultarCep(endereco) {
    axios
      .get(PATH_VIA_CEP.replace("%s", Mask.clearMask(endereco.cep)))
      .then(({ data }) => {
        if (data) {
          if (!Utils.isValueValid(data.erro)) {
            this.setState({
              enderecoAlterado: {
                ...this.state.enderecoAlterado,
                idCidadeIbge: data.ibge,
                bairro: data.bairro,
                complemento: data.complemento,
                cidade: data.localidade,
                logradouro: data.logradouro,
              },
              labelMensagemCepNaoEncontrado: "",
            });
          } else {
            this.setState({
              enderecoAlterado: {
                ...this.state.enderecoAlterado,
                idCidadeIbge: "",
                bairro: "",
                complemento: "",
                cidade: "",
                logradouro: "",
              },
              labelMensagemCepNaoEncontrado:
                "Endereço não encontrado para o CEP informado",
            });
          }
        } else {
          this.setState({
            enderecoAlterado: {
              ...this.state.enderecoAlterado,
              idCidadeIbge: "",
              bairro: "",
              complemento: "",
              cidade: "",
              logradouro: "",
            },
            labelMensagemCepNaoEncontrado:
              "Endereço não encontrado para o CEP informado",
          });
        }

        this.state.adicionarEndereco
          ? this.renderAdicionarEndereco()
          : this.renderTrocarEndereco({ ...endereco, ...data });
      })
      .catch((error) => console.log(error));
  }

  getCliente() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb() || {};
    cliente.cpfCnpj = Mask.getValueMaskCpfCnpj(cliente.cpfCnpj);
    cliente.cep = Mask.getValueMaskCep(cliente.cep);
    cliente.telefone = Mask.getValueMaskPhone(cliente.telefone);
    return cliente;
  }

  getEnderecos(endereco = this.state.enderecoAlterado) {
    let enderecoAlterado = endereco;
    enderecoAlterado.cep = Mask.clearMask(enderecoAlterado.cep);
    return [enderecoAlterado];
  }

  getDataCliente(cliente) {
    let enderecos = Array.from(cliente.enderecos);
    enderecos.map((end) => {
      end.cep = Mask.clearMask(end.cep);
      return true;
    });
    return {
      ...cliente,
      cpfCnpj: Mask.clearMask(cliente.cpfCnpj),
      telefone: Mask.clearMask(cliente.telefone),
    };
  }

  getDataNovoEndereco() {
    let endereco = this.state.enderecoAlterado;
    return {
      ...endereco,
      idCliente: this.state.cliente.idCliente,
      cep: Mask.clearMask(endereco.cep),
    };
  }

  renderTrocarEndereco(endereco) {
    ModalNotification.render(
      <Div style={{ color: this.state.corPrimaria }}>
        <I icon={Type.ICON.MOTORCYCLE} sizeIcon="2" margin="2" />
        <Label value="Alterar endereço de entrega" family="Light" />
      </Div>,
      this.renderEnderecoEntrega(endereco)
    );
  }

  renderAdicionarEndereco() {
    ModalNotification.render(
      <Div style={{ color: this.state.corPrimaria }}>
        <I icon={Type.ICON.MOTORCYCLE} sizeIcon="2" margin="2" />
        <Label value="Adicionar um novo endereço" family="Light" />
      </Div>,
      this.renderEnderecoEntrega(this.state.enderecoAlterado)
    );
  }

  adicionarNovoEndereco() {
    let cliente = this.getDataNovoEndereco();
    this.cadastrar(cliente);
  }

  atualizar(cliente) {
    Http.put(EndPoints.CLIENTE, cliente)
      .then(({ status, data }) => {
        if (status) {
          UsuarioUtils.setUsuarioLojaWeb(cliente);
          this.renderSucesso("Dados atualizados com sucesso!");
          this.forceUpdate();
        }
      })
      .catch((error) => console.log(error));
  }

  adicionarNovoEnderecoMemoria(endereco) {
    let cliente = this.state.cliente;
    cliente.enderecos = [...cliente.enderecos, endereco];
    UsuarioUtils.setUsuarioLojaWeb(cliente);
    //localStorage.setItem(Keys.KEY_CLIENTE_LOJA_WEB, JSON.stringify(cliente));
  }

  cadastrar(endereco) {
    endereco.idcliente = this.state.cliente.idcliente;
    Http.post(EndPoints.CLIENTE_ENDERECO, endereco)
      .then(({ status, data }) => {
        if (status) {
          this.renderSucesso("Endereço cadastrado com sucesso!");
          this.setState({ adicionarEndereco: false }, () =>
            this.adicionarNovoEnderecoMemoria(endereco)
          );
          this.forceUpdate();

          //Enquanto não tem endpoint que retorne o ID do cliente ou um GET nesse sentido para pegar o id do endereço cadastrado
          //UsuarioUtils.removerDadosAcessoUsuario();
          // UsuarioUtils.removerUsuarioLojaWeb();
          this.props.history.replace("/cliente");
        }
      })
      .catch((error) => console.log(error));
  }

  atualizarDados(endereco) {
    let enderecos = this.getEnderecos(endereco);
    Http.put(EndPoints.CLIENTE_ENDERECOS, enderecos)
      .then(({ status, data }) => {
        if (status) {
          this.renderSucesso("Dados atualizados com sucesso!");
          this.forceUpdate();
        }
      })
      .catch((error) => console.log(error));
  }

  atualizarCliente() {
    let cliente = this.getDataCliente(this.state.cliente);
    this.atualizar(cliente);
  }

  renderSucesso(msg) {
    ModalNotification.renderMessage({
      title: "Mensagem",
      body: (
        <Label
          value={msg}
          style={{ fontSize: 25, textAlign: "center" }}
          family="Light"
        />
      ),
      handlerConfirm: null,
      handlerCancel: () => Utils.refreshPage(),
      labelButtonCancel: "Fechar",
      responsive: "5",
    });
  }

  validarCadastro() {
    let endereco = this.state.enderecoAlterado || {};
    let erro = "";

    if (!Utils.isValueValid(endereco.numero))
      erro = "Preencha o campo numero do endereço de casa!";
    if (!Utils.isValueValid(endereco.bairro))
      erro = "Preencha o campo bairro do endereço de casa!";
    if (!Utils.isValueValid(endereco.logradouro))
      erro = "Preencha o campo lougradouro do endereço de casa!";
    if (!Mask.validarCep(endereco.cep)) erro = "Informe um CEP válido!";
    if (!Utils.isValueValid(endereco.descricao))
      erro = "Preencha o campo tipo do endereço!";
    if (!Utils.isValueValid(endereco.idCidadeIbge))
      erro = "Selecione a cidade!";

    let cliente = this.state.cliente;
    let enderecos = Array.from(cliente.enderecos);
    let enderecoPrincipal = enderecos.filter((end) => end.principal)[0];

    if (!Utils.isValueValid(enderecoPrincipal) && !endereco.principal) {
      erro = "Nenhum endereço está marcado como principal";
    }

    if (Utils.isValueValid(erro)) {
      ModalNotification.renderMessage({
        title: "Mensagem",
        body: (
          <Label
            value={erro}
            style={{ fontSize: 25, textAlign: "center" }}
            family="Light"
          />
        ),
        handlerConfirm: null,
        handlerCancel: () => {
          this.state.adicionarEndereco
            ? this.renderAdicionarEndereco()
            : this.renderTrocarEndereco(endereco);
        },
        labelButtonCancel: "Fechar",
        responsive: "5",
      });
    }

    return !Utils.isValueValid(erro);
  }

  renderTemplateNovamente(endereco) {
    this.state.adicionarEndereco
      ? this.renderAdicionarEndereco()
      : this.renderTrocarEndereco(endereco);
  }

  getNomesEndereco = () => {
    return [
      new SelectValue("Casa", "Casa"),
      new SelectValue("Trabalho", "Trabalho"),
      new SelectValue("Outro", "Outro"),
    ];
  };

  renderEnderecoEntrega(endereco) {
    // let endereco = end || {};
    const { enderecoAlterado } = this.state;
    return (
      <Div col="12" rounded column>
        {/* <Label
          value="Informe os seguintes dados para atualizar o endereço de entrega"
          family="Light"
          style={{ fontSize: 30 }}
        /> */}
        <Div inline="start">
          <If
            and
            value1={Utils.isValueValid(
              this.state.labelMensagemCepNaoEncontrado
            )}
          >
            <Label
              col="12"
              value={this.state.labelMensagemCepNaoEncontrado}
              style={{ color: Color.ECOMMERCE.LARANJA }}
            />
          </If>
          <Select
            values={this.getNomesEndereco()}
            label="Tipo de endereço"
            valueSelected={enderecoAlterado.descricao}
            handlerChange={(e) => {
              enderecoAlterado.descricao = e.target.value;
              this.setState({ enderecoAlterado: endereco });
            }}
          />

          <InputCep
            handlerChange={(e) => {
              enderecoAlterado.cep = e.target.value;
              this.setState({ enderecoAlterado: endereco });
            }}
            handlerBlur={() => {
              if (Mask.validarCep(enderecoAlterado.cep)) {
                this.setState(
                  {
                    labelMensagemCepNaoEncontrado: "",
                  },
                  () => this.consultarCep(enderecoAlterado)
                );
              } else {
                let enderecoAlteradoNovo = {
                  ...enderecoAlterado,
                  logradouro: "",
                  bairro: "",
                };
                this.setState({
                  enderecoAlterado: { ...enderecoAlteradoNovo },
                });
              }
            }}
            label="CEP"
            responsive="3"
            name="cep"
            value={enderecoAlterado.cep}
          />
          <If
            and
            value1={Utils.isValueValid(
              this.state.labelMensagemCepNaoEncontrado
            )}
          >
            <ComboBox
              label="Selecione uma cidade"
              responsive="3"
              labelPrimaryItem="Selecione"
              url="/cidade/estado/GO"
              name="idCidadeIbge"
              text="nomeCidade"
              valueSelected={enderecoAlterado.idCidadeIbge}
              handlerChange={(e) => {
                enderecoAlterado.idCidadeIbge = e.target.value;
                this.setState({ enderecoAlterado: enderecoAlterado });
              }}
            />
          </If>
          <InputDefault
            handlerChange={(e) => {
              enderecoAlterado.logradouro = e.target.value;
              this.setState({ enderecoAlterado: enderecoAlterado });
            }}
            label="Logradouro"
            responsive="3"
            disabled={
              !Utils.isValueValid(this.state.labelMensagemCepNaoEncontrado)
            }
            name="logradouro"
            value={enderecoAlterado.logradouro}
          />
          <InputInteger
            handlerChange={(e) => {
              enderecoAlterado.numero = e.target.value;
              this.setState({ enderecoAlterado: enderecoAlterado });
            }}
            label="Número"
            responsive="2"
            name="numero"
            value={enderecoAlterado.numero}
          />
          <InputDefault
            handlerChange={(e) => {
              enderecoAlterado.bairro = e.target.value;
              this.setState({ enderecoAlterado: enderecoAlterado });
            }}
            disabled={
              !Utils.isValueValid(this.state.labelMensagemCepNaoEncontrado)
            }
            label="Bairro"
            responsive="3"
            name="bairro"
            value={enderecoAlterado.bairro}
          />
          <InputDefault
            handlerChange={(e) => {
              enderecoAlterado.complemento = e.target.value;
              this.setState({ enderecoAlterado: enderecoAlterado });
            }}
            label="Complemento"
            responsive="3"
            name="complemento"
            value={enderecoAlterado.complemento}
          />

          <InputDefault
            handlerChange={(e) => {
              enderecoAlterado.referencia = e.target.value;
              this.setState({ enderecoAlterado: enderecoAlterado });
            }}
            label="Referência"
            responsive="4"
            name="referencia"
            value={enderecoAlterado.referencia}
          />
          <Div col="12" inline="start">
            <Checkbox
              label="Esse é o endereço principal?"
              checked={enderecoAlterado.principal}
              handlerChange={({ checked }) => {
                let cliente = this.state.cliente;
                let enderecos = Array.from(cliente.enderecos);
                enderecos.map((end) => (end.principal = false));

                enderecoAlterado.principal = checked;
                this.setState({ enderecoAlterado: enderecoAlterado, cliente });
              }}
              danger
            />
          </Div>
        </Div>
        <Label
          style={{
            backgroundColor: this.state.corPrimaria,
            color: "white",
            padding: 20,
            fontSize: 20,
            width: 250,
            borderRadius: 10,
            textAlign: "center",
          }}
          pointer
          family="Light"
          value={
            this.state.adicionarEndereco
              ? "Adicionar endereço"
              : "Atualizar endereço"
          }
          icon={Type.ICON.REFRESH}
          handlerClick={() => {
            if (this.validarCadastro()) {
              this.state.adicionarEndereco
                ? this.adicionarNovoEndereco()
                : this.atualizarDados();
            }
          }}
        />
      </Div>
    );
  }

  renderAdicionarEnderecoNovo() {
    return (
      <div>
        <Row
          col="12"
          style={{
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            fontSize: "18px",
            padding: "15px 20px",
            border: "1px solid #dee2e6",
            borderBottom: "none",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          family="Bold"
        >
          <Div responsive="4" padding="0">
            <Label
              value={this.state.labelEnderecos}
              style={{
                color: "rgb(87, 87, 87)",
                textTransform: "uppercase",
                width: "100%",
                marginLeft: "5px",
              }}
              family="SemiBold"
              marginBottom="0"
            />
          </Div>
        </Row>
        <Row col="12" className="border" style={{ padding: "10px 20px" }}>
          <AdicionarAlterarEndereco
            enderecoEditar={this.state.enderecoSelecionado}
            getNomesEndereco={this.getNomesEndereco}
            cadastrarEndereco={this.cadastrar}
            atualizarEndereco={this.atualizarDados}
            cliente={this.state.cliente}
          />
        </Row>
        <Div col="12" inline="start">
          <Div
            inline="center"
            handlerClick={() =>
              this.setState({
                mostrarAdicionarNovoEndereco: false,
                mostrarEndereco: true,
                enderecoSelecionado: {},
                labelEnderecos: "Adicionar novo endereço",
              })
            }
            pointer
            paddingLeft="0"
          >
            <I
              icon={Type.ICON.ARROW_ANGLE_LEFT}
              style={{
                color: Color.RGBA.AZUL,
                fontSize: 20,
                marginTop: 3,
                marginRight: 10,
                marginLeft: 0,
              }}
              pointer
            />
            <Label
              style={{
                color: Color.RGBA.AZUL,
                fontSize: 16,
                marginBottom: 0,
              }}
              value="Voltar para Meus Endereços"
              pointer
            />
          </Div>
        </Div>
      </div>
    );
  }

  renderEnderecoNovo() {
    const { cliente } = this.state;

    return (
      <div>
        <Row
          col="12"
          style={{
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            fontSize: "18px",
            padding: "15px 20px",
            border: "1px solid #dee2e6",
            borderBottom: "none",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          family="Bold"
        >
          <Div responsive="4" padding="0">
            <Label
              value="Meus Endereços"
              style={{
                color: "rgb(87, 87, 87)",
                textTransform: "uppercase",
                width: "100%",
                marginLeft: "5px",
              }}
              family="SemiBold"
              marginBottom="0"
            />
          </Div>
        </Row>
        <Row col="12" className="border" style={{ padding: "10px 20px" }}>
          <Div col="12" marginTop="2">
            {Array.from(cliente.enderecos).map((end, index) => {
              return (
                <Div
                  col="12"
                  style={{
                    borderRadius: "8px",
                    padding: "25px 10px 0px",
                    margin: "0px 0px 15px",
                    border: "1px solid #dee2e6",
                  }}
                >
                  <RadioCustom
                    checked={end.principal}
                    handlerChange={() => {
                      //  handlerEnderecoSelecionado(end);
                      //  setIdClienteEndereco(end.idClienteEndereco);
                    }}
                    left
                    value={
                      <>
                        <Label
                          value={end.descricao}
                          style={{
                            lineHeight: "15px",
                            fontSize: 14,
                            width: "100%",
                            color: Color.ECOMMERCE.CINZA_ESCURO,
                          }}
                          family="Bold"
                          className="text-ellipsis"
                          pointer
                        />
                        <Label
                          value={`${end.logradouro}, ${end.bairro}`}
                          style={{
                            lineHeight: "13px",
                            fontSize: 14,
                            width: "100%",
                            color: Color.ECOMMERCE.CINZA,
                          }}
                          pointer
                          family="Light"
                          className="text-ellipsis"
                        />

                        <I
                          pointer
                          icon={Type.ICON.EDIT}
                          handlerClick={() =>
                            this.setState({
                              enderecoSelecionado: { ...end },
                              labelEnderecos: "Editar Endereço",
                              mostrarAdicionarNovoEndereco: true,
                              mostrarEndereco: false,
                            })
                          }
                          style={{
                            color: Color.EMPRESA.PRIMARIA,
                            fontSize: 30,
                            marginRight: "10px",
                            position: "absolute",
                            right: "0",
                            top: "0",
                          }}
                        />
                      </>
                    }
                  />
                </Div>
              );
            })}
          </Div>
          <Row
            col="12"
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              margin: "0px 19px 25px",
            }}
            padding="0"
            pointer
            onClick={() => {
              this.setState(
                {
                  // enderecoAlterado: { descricao: "Casa" },
                  // adicionarEndereco: true,
                  mostrarAdicionarNovoEndereco: true,
                  mostrarEndereco: false,
                  enderecoSelecionado: {},
                  labelEnderecos: "Adicionar novo endereço",
                }
                //() => this.renderAdicionarEndereco()
              );
            }}
          >
            <I
              pointer
              icon={Type.ICON.PLUS_CIRCLE}
              style={{
                color: Color.EMPRESA.PRIMARIA,
                fontSize: 20,
                marginRight: "10px",
              }}
            />
            <Title
              value="Adicionar novo endereço"
              type="h6"
              className="label-valor-total-carrinho"
              style={{
                ...styles.labelCinza,
                marginBottom: "5px",
                marginTop: "-15px",

                color: Color.EMPRESA.PRIMARIA,
                fontSize: 14,
              }}
              family="Bold"
            />
          </Row>
        </Row>
      </div>
    );
  }

  verificarEmailEReenviarSenha(email) {
    Http.get(EndPoints.VERIFICAR_EMAIL.replace("%s", email))
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            this.reenviarSenha(email);
          } else {
            this.renderSucesso(data.mensagem);
          }
        } else {
          this.setState({
            mensagem:
              "Falha ao reenviar a senhar, verifique a conexão com a internet",
          });
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  reenviarSenha(email) {
    Http.put(EndPoints.USUARIO_RECUPERAR_SENHA, {
      email,
    })
      .then(({ status, data }) => {
        if (status) {
          this.renderSucesso(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  renderDadosParaAtualizarSenha() {
    let cliente = this.getCliente();

    let emailValido =
      Utils.isValueValid(cliente.login) && Mask.validarEmail(cliente.login);
    return (
      <Div>
        <Label
          family="SemiBold"
          value="Reenviar a senha para o email"
          style={{
            backgroundColor: emailValido
              ? this.state.corPrimaria
              : Color.ECOMMERCE.CINZA,
            color: "white",
            fontSize: 25,
            padding: 10,
            borderRadius: 10,
            marginTop: 15,
            width: "100%",
            textAlign: "center",
          }}
          pointer
          handlerClick={(e) => {
            if (emailValido) {
              this.verificarEmailEReenviarSenha(cliente.login);
            } else {
              this.setState({
                mensagem: "Falha ao reenviar a senha!",
              });
            }
          }}
        />
      </Div>
    );
  }

  renderDadosPessoaisNovo() {
    const { cliente } = this.state;
    return (
      <div>
        <Row
          col="12"
          style={{
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            fontSize: "18px",
            padding: "15px 20px",
            border: "1px solid #dee2e6",
            borderBottom: "none",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          family="Bold"
        >
          <Div responsive="4" padding="0">
            <Label
              value="Dados Pessoais"
              style={{
                color: "rgb(87, 87, 87)",
                textTransform: "uppercase",
                width: "100%",
                marginLeft: "5px",
              }}
              family="SemiBold"
              marginBottom="0"
            />
          </Div>
        </Row>
        <Row col="12" className="border" style={{ padding: "10px 20px" }}>
          <InputDefault
            label="E-mail:"
            disabled
            name="email"
            value={cliente.email}
            handlerChange={this.handlerChange}
            autoComplete="off"
            responsive="6"
            placeholder="Email"
            styleContainer={{ marginBottom: "10" }}
            style={styles.inputCartao}
          />

          <InputCpfCnpj
            handlerChange={this.handlerChange}
            name="cpfCnpj"
            label="CPF/CNPJ:"
            value={cliente.cpfCnpj}
            placeholder="CPF / CNPJ"
            disabled
            styleContainer={{ marginBottom: "10", padding: "0px 17px" }}
            style={styles.inputCartao}
            responsive="6"
          />

          <InputDefault
            label="Nome Completo: "
            name="nome"
            value={cliente.nome}
            handlerChange={this.handlerChange}
            autoComplete="off"
            responsive="6"
            placeholder="Escreva seu nome completo"
            styleContainer={{ marginBottom: "10" }}
            style={styles.inputCartao}
          />

          <InputDefault
            label="Telefone"
            handlerChange={(e) => {
              Mask.maskPhone(e);
              this.handlerChange(e);
            }}
            autoComplete="off"
            responsive="6"
            name="telefone"
            value={cliente.telefone}
            placeholder="Telefone com DDD"
            styleContainer={{ marginBottom: "10" }}
            style={styles.inputCartao}
          />

          <Div col="12" className="border" style={{ padding: "10px 20px" }}>
            <Div style={styles.containerCheckBox} inline="between">
              <Checkbox
                label="Quero ser contatado pela loja"
                handlerChange={(e) =>
                  this.handlerChange({
                    target: { name: "permiteSerContatado", value: e.checked },
                  })
                }
                margin="2"
                checked={cliente.permiteSerContatado}
                //danger
              />
              <Checkbox
                label="Desejo receber ofertas da loja"
                handlerChange={(e) =>
                  this.handlerChange({
                    target: { name: "permiteReceberOferta", value: e.checked },
                  })
                }
                margin="2"
                checked={cliente.permiteReceberOferta}
                //danger
              />
            </Div>
          </Div>
          <Div
            col="12"
            inline="center"
            handlerClick={() => this.atualizarCliente()}
          >
            <Label
              value="Alterar dados pessoais"
              style={{
                ...styles.btnAlterarDadosPessoais,
                backgroundColor: this.state.corPrimaria,
              }}
              pointer
            />
          </Div>
        </Row>
      </div>
    );
  }

  renderDadosPessoais() {
    const { cliente } = this.state;

    return (
      <Div>
        <Label
          value="Dados pessoais 2"
          style={styles.labelTitle}
          family="Bold"
        />
        <Label value="Email" style={styles.label} family="Light" />
        <Input
          handlerChange={this.handlerChange}
          name="email"
          value={cliente.email}
          placeholder="Email"
          disabled
          style={styles.input}
        />
        <Label value="Nome" style={styles.label} family="Light" />
        <Input
          id="inputNomeCompletoCadastroCliente"
          handlerChange={this.handlerChange}
          name="nome"
          value={cliente.nome}
          placeholder="Nome completo"
          style={styles.input}
        />
        <InputCpfCnpj
          handlerChange={this.handlerChange}
          name="cpfCnpj"
          label="CPF/CNPJ"
          value={cliente.cpfCnpj}
          placeholder="CPF / CNPJ"
          disabled
          style={styles.input}
          styleLabel={{ color: Color.EMPRESA.PRIMARIA, marginTop: 15 }}
        />

        <Label value="Telefone" style={styles.label} family="Light" />
        <Input
          handlerChange={(e) => {
            Mask.maskPhone(e);
            this.handlerChange(e);
          }}
          name="telefone"
          value={cliente.telefone}
          placeholder="Telefone"
          style={styles.input}
        />
        <Div style={styles.containerCheckBox} inline="between">
          <Checkbox
            label="Quero ser contatado pela loja"
            handlerChange={(e) =>
              this.handlerChange({
                target: { name: "permiteSerContatado", value: e.checked },
              })
            }
            margin="2"
            checked={cliente.permiteSerContatado}
            //danger
          />
          <Checkbox
            label="Desejo receber ofertas da loja"
            handlerChange={(e) =>
              this.handlerChange({
                target: { name: "permiteReceberOferta", value: e.checked },
              })
            }
            margin="2"
            checked={cliente.permiteReceberOferta}
            //danger
          />
        </Div>
        <Div inline="center" handlerClick={() => this.atualizarCliente()}>
          <Label
            value="Alterar dados pessoais"
            style={{
              ...styles.btnAlterarDadosPessoais,
              backgroundColor: this.state.corPrimaria,
            }}
            pointer
          />
        </Div>
      </Div>
    );
  }

  handlerChange(event) {
    const { name, value } = event.target;
    this.setState({
      cliente: {
        ...this.state.cliente,
        [name]: value,
      },
    });
  }

  render() {
    let corSecundaria = Color.EMPRESA.PRIMARIA;

    return (
      <Div className="w-100" padding="2" style={{ marginTop: "-60px" }}>
        <Row padding="3" style={{ alignItems: "center" }}>
          <I
            icon={Type.ICON.HOME}
            pointer
            style={{ fontSize: 24, marginRight: 5, color: "#BABABA" }}
          />
          <Label
            value="Inicio"
            style={{ fontSize: 16, color: "#BABABA" }}
            family="Light"
            pointer
            margin="2"
            handlerClick={() => this.props.history.push("/")}
          />
          <Label
            value=">"
            style={{ fontSize: 16, color: "rgb(54, 162, 235)" }}
            margin="2"
          />

          <Label
            value="Meus Dados"
            family="bold"
            style={{ fontSize: 16, color: "#373646" }}
            margin="2"
          />
        </Row>
        <Row inline="center" className="w-100">
          <Div
            responsive="3"
            className="d-flex flex-column justify-content-start align-items-start align-self-start"
            padding="5"
            style={styles.containerMenu}
          >
            <Div
              style={
                this.state.mostrarDadosPessoais
                  ? {
                      ...styles.meusDadosActive,
                      backgroundColor: corSecundaria,
                    }
                  : { ...styles.meusDados, color: corSecundaria }
              }
              pointer
              handlerClick={() =>
                this.setState({
                  mostrarDadosPessoais: true,
                  mostrarAlteracaoSenha: false,
                  mostrarEndereco: false,
                })
              }
            >
              <I icon={Type.ICON.USER} margin="1" pointer />
              <Label value="Meus dados" family="SemiBold" margin="2" pointer />
            </Div>
            <Div
              style={
                this.state.mostrarAlteracaoSenha
                  ? {
                      ...styles.meusDadosActive,
                      backgroundColor: corSecundaria,
                    }
                  : { ...styles.meusDados, color: corSecundaria }
              }
              pointer
              handlerClick={() =>
                this.setState({
                  mostrarAlteracaoSenha: true,
                  mostrarDadosPessoais: false,
                  mostrarEndereco: false,
                })
              }
            >
              <I icon={Type.ICON.KEY} margin="1" pointer />
              <Label
                value="Alterar senha"
                family="SemiBold"
                margin="2"
                pointer
              />
            </Div>
            <Div
              style={
                this.state.mostrarEndereco ||
                this.state.mostrarAdicionarNovoEndereco
                  ? {
                      ...styles.meusDadosActive,
                      backgroundColor: corSecundaria,
                    }
                  : { ...styles.meusDados, color: corSecundaria }
              }
              pointer
              handlerClick={() =>
                this.setState({
                  mostrarDadosPessoais: false,
                  mostrarAlteracaoSenha: false,
                  mostrarEndereco: true,
                  mostrarAdicionarNovoEndereco: false,
                })
              }
            >
              <I icon={Type.ICON.MAP_MARKER} margin="2" pointer />
              <Label value="Endereços" family="SemiBold" margin="2" pointer />
            </Div>
          </Div>
          <Div responsive="9" marginBottom="3">
            <Div col="12">
              <If and value1={this.state.mostrarDadosPessoais}>
                <Div marginTop="2" col="12">
                  {this.renderDadosPessoaisNovo()}
                </Div>
              </If>
              <If and value1={this.state.mostrarEndereco}>
                <Div marginTop="2" col="12">
                  {this.renderEnderecoNovo()}
                </Div>
              </If>
              <If and value1={this.state.mostrarAlteracaoSenha}>
                <Div marginTop="2" col="12">
                  {this.renderDadosParaAtualizarSenha()}
                </Div>
              </If>
              <If and value1={this.state.mostrarAdicionarNovoEndereco}>
                <Div marginTop="2" col="12">
                  {this.renderAdicionarEnderecoNovo()}
                </Div>
              </If>
            </Div>
          </Div>
        </Row>
      </Div>
    );
  }
}

export default DadosPessoaisCliente;
