import React, { useEffect, useState } from "react";
import Div from "../layout/div/Div";
import Row from "../layout/Row";
import Label from "../texts/label/Label";
import Button from "../buttons/Button";

export default function CookiesAlert({ mobile = false }) {
  const [state, setState] = useState(true);

  function aceitarCookies() {
    sessionStorage.setItem("cookiesAccept", JSON.stringify(true));
  }

  useEffect(() => {
    const valor = JSON.parse(sessionStorage.getItem("cookiesAccept"));

    if (valor) {
      setState(false);
    }
  }, []);

  return (
    <Div
      style={{
        position: "fixed",
        bottom: mobile ? 209 : 100,
        left: 0,
        width: "100%",
        miHeight: "88px",
        padding: mobile ? 10 : 25,
        margin: "0 !important",
        zIndex: 999999999999999,
        background: "#212327",

        opacity: state ? 1 : 0,
        visibility: state ? "visible" : "hidden",
        transform: "translateY(100%)",
        transition: "all 500msease-out",
      }}
      col="12"
    >
      <Row col="12" padding="0">
        <Row responsive={mobile ? "12" : "11"} padding="0">
          <Label
            style={{
              color: "#ecf0f1",
              fontSize: "16px",
              marginRight: 10,
              textAlign: "center",
            }}
            family="Bold"
            value="O nosso site coleta cookies próprios e de terceiros para proporcionar uma experiência personalizada ao usuário, apresentar publicidade relevante de acordo com o seu perfil e melhorar a qualidade do nosso site."
          />
        </Row>
        <Row
          responsive={mobile ? "12" : "1"}
          display="center"
          style={{
            justifyContent: "center",
          }}
        >
          <Button
            handlerClick={() => {
              setState(false);
              aceitarCookies();
            }}
            value="Entendi"
            style={{ padding: "0px 20px", height: "40px" }}
            btn="primary"
          />
        </Row>
      </Row>
    </Div>
  );
}
