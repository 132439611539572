import React, { Component } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import {
  Div,
  Label,
  ModalCustom,
  Row,
  SelectValue,
  Table,
  Title,
} from "../../components";
import AssinaturaService from "../../service/AssinaturaService";
import { Color, DateUtils, Type } from "../../utilities";
import CarrinhoAssinatura from "./CarrinhoAssinatura";
import TemplateFilterAssinaturas from "./TemplateFilterAssinaturas";

const style = {
  labelTitulo: {
    color: "#000",
    fontSize: "18px",
    fontWeight: "bold",
  },
  label: {
    color: Color.ECOMMERCE.CINZA,
    fontSize: "14px",
    fontWeight: "bold",
    flexDirection: "row !important",
    minWidth: "120px",
    justifyContent: "space-between",
    display: "flex",
  },
  box1: {
    color: Color.ECOMMERCE.CINZA,
    fontSize: "14px",
    fontWeight: "bold",
    flexDirection: "row !important",
    minWidth: "95px",
    justifyContent: "space-between",
    display: "flex",
  },
  box2: {
    color: Color.ECOMMERCE.CINZA,
    fontSize: "14px",
    fontWeight: "bold",
    flexDirection: "row !important",
    minWidth: "145px",
    justifyContent: "space-between",
    display: "flex",
    width: "100%",
  },
  labelInfo: {
    color: Color.ECOMMERCE.CINZA,
    fontSize: "12px",
  },
  valor: {
    fontSize: "16px",
    marginTop: "-16px",
  },
  containerLabels: {
    //marginLeft: "15px",
  },
  box: {
    padding: "10px 0px 20px",
    minWidth: "600px",
    marginBottom: "30px",
  },
};

class AssinaturasListar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectPosicao: [
        new SelectValue(Type.POSICAO.TODAS, "Todos", true),
        new SelectValue(Type.POSICAO.ATIVA, "Ativas", false),
        new SelectValue(Type.POSICAO.CANCELADA, "Cancelada", false),
        new SelectValue(Type.POSICAO.PAUSADA, "Pausadas", false),
      ],
      selectFormaPagamento: [
        new SelectValue(null, "Todos", true),
        new SelectValue(Type.PAGAMENTO.DINHEIRO, "Dinheiro", false),
        new SelectValue(Type.PAGAMENTO.CREDITO, "Crédito", false),
        new SelectValue(Type.PAGAMENTO.DEBITO, "Débito", false),
      ],
      selectPagamento: [
        new SelectValue(null, "Todos", true),
        new SelectValue(Type.PAGAMENTO.ONLINE, "Pagamento Online", false),
        new SelectValue(
          Type.PAGAMENTO.OFFLINE,
          "Pagamento na Entrega/Retirada",
          false
        ),
      ],
      selectEntrega: [
        new SelectValue(null, "Todos", true),
        new SelectValue(Type.OPCOES_ENTREGA.ENTREGA, "Entrega", false),
        new SelectValue(Type.OPCOES_ENTREGA.RETIRADA, "Retirada", false),
      ],
      selectTipoFrequencia: [
        new SelectValue(null, "Todos", true),
        new SelectValue(Type.ASSINATURA.MENSAL, "Mensal", false),
        new SelectValue(Type.ASSINATURA.QUINZENAL, "Quinzenal", false),
        new SelectValue(Type.ASSINATURA.SEMANAL, "Semanal", false),
      ],
      selectPedidos: [
        new SelectValue(null, "Todos", true),
        new SelectValue("PENDENTES", "Pendentes", false),
        new SelectValue("CONCLUIDOS", "Concluidos", false),
      ],
      pedidoConsulta: null,
      assinaturasLista: [
        {
          codigo: "348349",
          descricao: "Nome da lista",
          cliente: "Client Nome Completo",
          produtoQtd: 42,
          data: "04-04-2022",
          assinatura: "Semanal",
        },
      ],
      cliente: "",
      closeModal: false,
      filtroPedidosConcluidos: false,
      filtroPedidosPendentes: true,
      dataFiltroInicial: "2022-04-01",
      dataFiltroFinal: "2022-06-15",
      assinaturas: [],
      pedidosPendentes: false,
      pedidosConcluidos: false,
      pausados: false,
      dashboard: {
        quantidadeAssinatura: "0",
        quantidadeAssinaturaAtiva: "0",
        quantidadeAssinaturaPausada: "0",
        quantidadeAssinaturaCancelada: "0",
        quantidadeAssinaturaMensal: "0",
        quantidadeAssinaturaQuinzenal: 0,
        quantidadeAssinaturaSemanal: "0",
        quantidadeClienteAssinados: "0",
        valorTotalAssinatura: "0,00",
        valorTotalAssinaturaMensal: "0,00",
        valorTotalAssinaturaQuinzenal: "0,00",
        valorTotalAssinaturaSemanal: "0,00",
        valorTotalAssinaturaCancelada: 0,
        ticketMedioTotalAssinatura: "0,00",
        ticketMedioTotalAssinaturaMensal: "0,00",
        ticketMedioTotalAssinaturaQuinzenal: "0,00",
        ticketMedioTotalAssinaturaSemanal: "0,00",
        valorTotalPedidoConcluidos: "0,00",
        quantidadeTotalPedidoConcluidos: "0",
        valorTotalPedidoConcluidosProdutos: "0,00",
        valorTotalPedidoConcluidosTaxas: "0,00",
        ticketMedioPedidoConcluidosProdutos: "0,00",
        ticketMedioPedidoConcluidosTaxas: "0,00",
        ticketMedioPedidoConcluidos: "0,00",
      },
      filtro: {
        posicao: Type.POSICAO.TODAS,
        tipoEntrega: null,
        tipo: null,
        tipoPagamento: null,
        tipoFormaPagamento: null,
        dataFiltroInicial: this.dataMensalAtual(1),
        dataFiltroFinal: this.dataMensalAtual(30),
        filtroPedidosConcluidos: false,
        filtroPedidosPendentes: false,
      },
    };
  }

  componentDidMount() {
    let { filtro } = this.state;
    this.consultarAssinatura(filtro);
    this.getDashboard(filtro.dataFiltroInicial, filtro.dataFiltroFinal);
  }

  addDiasDataHojeBr(days) {
    // Date.prototype.addDays = function (days) {
    //   const date = new Date(this.valueOf());
    //   date.setDate(date.getDate() + days);
    //   return date;
    // };

    let date = new Date();
    let newDate = DateUtils.addDays(date, days);
    return moment(newDate).format("YYYY-MM-DD");
  }

  dataMensalAtual(day) {
    const date = new Date();

    if (date.getMonth() === 1) {
      return moment(
        new Date(date.getFullYear(), date.getMonth(), day > 28 ? 28 : day)
      ).format("YYYY-MM-DD");
    }

    return moment(new Date(date.getFullYear(), date.getMonth(), day)).format(
      "YYYY-MM-DD"
    );
  }

  handleChangeInput = (name, value) => {
    let newFiltro = {
      ...this.state.filtro,
      [name]: value === "Todos" ? null : value,
    };

    this.setState(
      {
        filtro: newFiltro,
      },
      this.consultarAssinatura(newFiltro)
    );

    //this.getDashboard(newFiltro.dataFiltroInicial, newFiltro.dataFiltroFinal)
  };

  editarAssinatura(assinatura) {
    this.props.history.push({
      pathname: "/admin/assinaturas/editar",
      state: { assinatura },
    });
  }

  getDashboard(dataInicial, dataFinal) {
    let parametros = {
      dataFiltroInicial: dataInicial,
      dataFiltroFinal: dataFinal,
    };

    AssinaturaService.adminDashboardAssinatura(parametros).then(
      ({ status, data }) => {
        console.log(data);
        if (status) {
          this.setState({
            dashboard: data,
          });
        } else {
          this.setState({
            dashboard: [],
          });
        }
      }
    );
  }

  consultarAssinatura(filtro) {
    AssinaturaService.adminListarAssinaturas(filtro).then(
      ({ status, data }) => {
        if (status) {
          this.setState({
            assinaturas: data,
          });
        }
      }
    );
  }

  setCloseModal = (boolean) => {
    this.setState({
      closeModal: boolean,
    });
  };

  handlePedidos = (value) => {
    let newFiltro = {
      ...this.state.filtro,
      filtroPedidosConcluidos: value === "CONCLUIDOS" ? true : false,
      filtroPedidosPendentes: value === "PENDENTES" ? true : false,
    };

    this.setState(
      {
        filtro: newFiltro,
        pedidoConsulta: value,
      },
      this.consultarAssinatura(newFiltro)
    );
  };

  renderFiltros() {
    const {
      filtro,
      selectFormaPagamento,
      selectPagamento,
      selectTipoFrequencia,
      selectPedidos,
      selectEntrega,
      selectPosicao,
      pedidoConsulta,
    } = this.state;

    return (
      <TemplateFilterAssinaturas
        handleChangeInput={this.handleChangeInput}
        tipoSelecionado={filtro.tipo}
        dataInicial={filtro.dataFiltroInicial}
        dataExpiracao={filtro.dataFiltroFinal}
        selectTipoFrequencia={selectTipoFrequencia}
        selectFormaPagamento={selectFormaPagamento}
        selectPagamento={selectPagamento}
        selectEntrega={selectEntrega}
        selectPosicao={selectPosicao}
        posicao={filtro.posicao}
        tipoFormaPagamento={filtro.tipoFormaPagamento}
        tipoPagamento={filtro.tipoPagamento}
        tipoEntrega={filtro.tipoEntrega}
        selectPedidos={selectPedidos}
        pedidoConsulta={pedidoConsulta}
        handlePedidos={this.handlePedidos}
      />
    );
  }

  carrinhoAssinatura(rede) {
    this.setState({
      closeModal: true,
    });
  }

  render() {
    const { assinaturas, dashboard, filtro } = this.state;

    return (
      <>
        <ModalCustom
          title="Produtos da Lista"
          showModal={this.state.closeModal}
          setShowModal={this.setCloseModal}
          confirm={() => {}}
        >
          <CarrinhoAssinatura />
        </ModalCustom>
        <Div
          padding="2"
          paddingRight="5"
          marginLeft="3"
          bg={Color.NODE.WHITE}
          col="12"
          shadow
          className="border-left-primary"
        >
          <Row col="12" padding="3">
            <Title value="Assinaturas" type="h4" family="Bold" />
          </Row>

          <Row col="12" style={{ justifyContent: "space-around" }}>
            <Div className="border" shadow columns style={style.box}>
              <Row
                style={style.containerLabels}
                col="12"
                column
                marginBottom="2"
                marginLeft="2"
              >
                <Label style={style.labelTitulo} marginBottom="0">
                  Assinaturas
                </Label>
              </Row>

              <Row col="12">
                <Div
                  col="3"
                  column
                  style={{ borderRight: "2px solid #dee2e6" }}
                >
                  <Div style={style.box1} marginBottom="1" column>
                    <span style={{ color: Color.ECOMMERCE.CINZA }}> Total</span>
                    <span style={{ color: Color.RGBA.VERDE, fontSize: "45px" }}>
                      {" "}
                      {dashboard.quantidadeAssinatura}
                    </span>
                  </Div>
                </Div>

                <Div
                  col="4"
                  style={{ paddingTop: "10px" }}
                  className="d-flex flex-column align-items-start"
                >
                  <Div style={style.box1} marginBottom="1">
                    <span style={style.labelInfo}>Ativas:</span>
                    <span style={{ color: "#000" }}>
                      {" "}
                      {dashboard.quantidadeAssinaturaAtiva}
                    </span>
                  </Div>

                  {/* <Label style={style.box1} marginBottom="1">
                    Ativas:
                    <span style={{ color: "#000" }} > {dashboard.quantidadeAssinaturaAtiva}</span>
                  </Label> */}

                  <Div style={style.box1} marginBottom="1">
                    <span style={style.labelInfo}>Pausadas: </span>
                    <span style={{ color: "#000" }}>
                      {" "}
                      {dashboard.quantidadeAssinaturaPausada}
                    </span>
                  </Div>

                  <Div style={style.box1} marginBottom="0">
                    <span style={style.labelInfo}>Canceladas: </span>
                    <span style={{ color: "#000" }}>
                      {" "}
                      {dashboard.quantidadeAssinaturaCancelada}
                    </span>
                  </Div>

                  <Div style={style.box1} marginBottom="0">
                    <span style={style.labelInfo}>Clientes:</span>
                    <span style={{ color: "#000" }}>
                      {" "}
                      {dashboard.quantidadeClienteAssinados}
                    </span>
                  </Div>
                </Div>

                <Div
                  col="4"
                  className="d-flex flex-column align-items-start"
                  style={{ paddingTop: "10px" }}
                >
                  <Div style={style.box1} marginBottom="1">
                    <span style={style.labelInfo}>Mensal: </span>
                    <span style={{ color: "#000" }}>
                      {" "}
                      {dashboard.quantidadeAssinaturaMensal}
                    </span>
                  </Div>

                  <Div style={style.box1} marginBottom="1">
                    <span style={style.labelInfo}>Quinzenal: </span>
                    <span style={{ color: "#000" }}>
                      {" "}
                      {dashboard.quantidadeAssinaturaQuinzenal}
                    </span>
                  </Div>

                  <Div style={style.box1} marginBottom="0">
                    <span style={style.labelInfo}>Semanal: </span>
                    <span style={{ color: "#000" }}>
                      {" "}
                      {dashboard.quantidadeAssinaturaSemanal}
                    </span>
                  </Div>
                </Div>
              </Row>
            </Div>

            <Div col="4" className="border" shadow columns style={style.box}>
              <Row
                style={style.containerLabels}
                col="12"
                column
                marginBottom="2"
              >
                <Label style={style.labelTitulo} marginBottom="0">
                  Valor Produtos Assinados
                </Label>
              </Row>
              <Row col="12">
                <Div
                  col="4"
                  column
                  style={{ borderRight: "2px solid #dee2e6" }}
                >
                  <Div style={style.box2} marginBottom="1" column>
                    <span style={{ color: Color.ECOMMERCE.CINZA }}> Total</span>
                    <span style={{ color: Color.RGBA.VERDE, fontSize: "27px" }}>
                      {" "}
                      R$ {dashboard.valorTotalAssinatura}
                    </span>
                  </Div>
                </Div>

                <Div
                  col="4"
                  className="d-flex flex-column align-items-start"
                  style={{ paddingTop: "10px" }}
                >
                  <Div style={style.box2} marginBottom="1">
                    <span style={style.labelInfo}>Mensal: </span>
                    <span style={{ color: "#000" }}>
                      {" "}
                      R$ {dashboard.valorTotalAssinaturaMensal}
                    </span>
                  </Div>

                  <Div style={style.box2} marginBottom="1">
                    <span style={style.labelInfo}>Quinzenal: </span>
                    <span style={{ color: "#000" }}>
                      {" "}
                      R$ {dashboard.valorTotalAssinaturaQuinzenal}
                    </span>
                  </Div>

                  <Div style={style.box2} marginBottom="0">
                    <span style={style.labelInfo}>Semanal: </span>
                    <span style={{ color: "#000" }}>
                      {" "}
                      R$ {dashboard.valorTotalAssinaturaSemanal}
                    </span>
                  </Div>
                </Div>

                <Div
                  col="2"
                  className="d-flex flex-column align-items-start"
                  style={{ paddingTop: "10px" }}
                >
                  {/* <Label style={style.box1} marginBottom="1">
                    Total:
                    <span style={{ color: "#000" }} > R$ {dashboard.valorTotalAssinatura}</span>
                  </Label> */}

                  <Div style={style.box2} marginBottom="0">
                    <span style={style.labelInfo}>Canceladas: </span>
                    <span style={{ color: Color.ECOMMERCE.VERMELHO_ERROR }}>
                      {" "}
                      R${" "}
                      {dashboard.valorTotalAssinaturaCancelada
                        ? dashboard.valorTotalAssinaturaCancelada
                        : "0,00"}
                    </span>
                  </Div>
                </Div>
              </Row>
            </Div>

            <Div col="4" className="border" shadow columns style={style.box}>
              <Row
                style={style.containerLabels}
                col="12"
                column
                marginBottom="2"
              >
                <Label style={style.labelTitulo} marginBottom="0">
                  {`Pedidos de ${DateUtils.getDateFormat(
                    filtro.dataFiltroInicial,
                    "DD/MM/YYYY"
                  )} à ${DateUtils.getDateFormat(
                    filtro.dataFiltroFinal,
                    "DD/MM/YYYY"
                  )}`}
                </Label>
              </Row>
              <Row col="12">
                <Div
                  col="4"
                  column
                  style={{ borderRight: "2px solid #dee2e6" }}
                >
                  <Div style={style.box2} marginBottom="1" column>
                    <span style={{ color: Color.ECOMMERCE.CINZA }}> Total</span>
                    <span style={{ color: Color.RGBA.VERDE, fontSize: "27px" }}>
                      {" "}
                      R$ {dashboard.valorTotalPedidoConcluidos}
                    </span>
                  </Div>
                </Div>

                <Div
                  col="4"
                  className="d-flex flex-column align-items-start"
                  style={{ paddingTop: "10px", paddingRight: "10px" }}
                >
                  <Div style={style.box2} marginBottom="1">
                    <span style={style.labelInfo}>Qdte. Pedidos: </span>
                    <span style={{ color: "#000" }}>
                      {dashboard.quantidadeTotalPedidoConcluidos}
                    </span>
                  </Div>

                  <Div style={style.box2} marginBottom="1">
                    <span style={style.labelInfo}>Total Produtos: </span>
                    <span style={{ color: "#000" }}>
                      {dashboard.valorTotalPedidoConcluidosProdutos}
                    </span>
                  </Div>

                  <Div style={style.box2} marginBottom="0">
                    <span style={style.labelInfo}>Total Taxas: </span>
                    <span style={{ color: "#000" }}>
                      {" "}
                      R$ {dashboard.valorTotalPedidoConcluidosTaxas}
                    </span>
                  </Div>
                </Div>

                <Div
                  col="4"
                  className="d-flex flex-column align-items-start"
                  style={{
                    paddingTop: "10px",
                    paddingRight: "0px",
                    paddingLeft: "10px",
                  }}
                >
                  {/* <Label style={style.box1} marginBottom="1">
                    Total:
                    <span style={{ color: "#000" }} > R$ {dashboard.valorTotalAssinatura}</span>
                  </Label> */}

                  <Div style={style.box2} marginBottom="0">
                    <span style={style.labelInfo}>TKM. Produtos: </span>
                    <span style={{ color: "#000" }}>
                      {" "}
                      R${" "}
                      {dashboard.ticketMedioPedidoConcluidosProdutos
                        ? dashboard.ticketMedioPedidoConcluidosProdutos
                        : "0,00"}
                    </span>
                  </Div>

                  <Div style={style.box2} marginBottom="0">
                    <span style={style.labelInfo}>TKM. Taxas: </span>
                    <span style={{ color: "#000" }}>
                      {" "}
                      R${" "}
                      {dashboard.ticketMedioPedidoConcluidosTaxas
                        ? dashboard.ticketMedioPedidoConcluidosTaxas
                        : "0,00"}
                    </span>
                  </Div>

                  <Div style={style.box2} marginBottom="0">
                    <span style={style.labelInfo}>Ticket Médio: </span>
                    <span style={{ color: Color.RGBA.VERDE }}>
                      {" "}
                      R${" "}
                      {dashboard.ticketMedioPedidoConcluidos
                        ? dashboard.ticketMedioPedidoConcluidos
                        : "0,00"}
                    </span>
                  </Div>
                </Div>
              </Row>
            </Div>

            <Div col="4" className="border" shadow columns style={style.box}>
              <Row
                style={style.containerLabels}
                col="12"
                column
                marginBottom="2"
              >
                <Label style={style.labelTitulo} marginBottom="0">
                  Ticket Médio
                </Label>
              </Row>
              <Row col="12">
                <Div
                  col="4"
                  column
                  style={{ borderRight: "2px solid #dee2e6" }}
                >
                  <Div style={style.box2} marginBottom="1" column>
                    <span style={style.labelInfo}> Total</span>
                    <span style={{ color: Color.RGBA.VERDE, fontSize: "27px" }}>
                      R${" "}
                      {dashboard.ticketMedioTotalAssinatura
                        ? dashboard.ticketMedioTotalAssinatura
                        : "0,00"}
                    </span>
                  </Div>
                </Div>

                <Div
                  col="4"
                  className="d-flex flex-column align-items-start"
                  style={{ paddingTop: "10px" }}
                >
                  <Div style={style.box2} marginBottom="1">
                    <span style={style.labelInfo}>Mensal: </span>
                    <span style={{ color: "#000" }}>
                      {" "}
                      R$ {dashboard.ticketMedioTotalAssinaturaMensal}
                    </span>
                  </Div>

                  <Div style={style.box2} marginBottom="1">
                    <span style={style.labelInfo}>Quinzenal: </span>
                    <span style={{ color: "#000" }}>
                      {" "}
                      R$ {dashboard.ticketMedioTotalAssinaturaQuinzenal}
                    </span>
                  </Div>

                  <Div style={style.box2} marginBottom="0">
                    <span style={style.labelInfo}>Semanal: </span>
                    <span style={{ color: "#000" }}>
                      {" "}
                      R$ {dashboard.ticketMedioTotalAssinaturaSemanal}
                    </span>
                  </Div>
                </Div>

                <Div
                  col="2"
                  className="d-flex flex-column align-items-start"
                  style={{ paddingTop: "10px" }}
                >
                  {/* <Label style={style.box1} marginBottom="1">
                    Total:
                    <span style={{ color: "#000" }} > R$  {
                      dashboard.ticketMedioTotalAssinatura ?
                        dashboard.ticketMedioTotalAssinatura :
                        "0,00"
                    }</span>
                  </Label> */}

                  <Div style={style.box2} marginBottom="0">
                    <span style={style.labelInfo}>Concluídos: </span>
                    <span style={{ color: "#000" }}>
                      {" "}
                      R${" "}
                      {dashboard.ticketMedioPedidoConcluidos
                        ? dashboard.ticketMedioPedidoConcluidos
                        : "0,00"}
                    </span>
                  </Div>
                </Div>
              </Row>
            </Div>
          </Row>

          <Div col="12" paddingRight="0">
            <Table
              columns={[
                { value: "Referência", align: "center" },
                { value: "Cliente", align: "center" },
                { value: "Tipo", align: "center" },
                { value: "Tipo de Entrega", align: "center" },
                { value: "Assinante Desde", align: "center" },
                { value: "Assinatura", align: "center" },
                { value: "Últ. Pedido", align: "center" },
                { value: "Próx. Pedido", align: "center" },
                { value: "Qdt. Pedidos", align: "center" },
                { value: "Vlr. Últ. Pedido", align: "center" },
                { value: "St. Pedido", align: "center" },
              ]}
              fields={[
                {
                  value: "referencia",
                  type: Type.DADO.DATE,
                },
                {
                  value: "nomeCliente",
                  type: Type.DADO.STRING,
                },
                {
                  value: "tipo",
                  type: Type.DADO.STRING,
                },
                {
                  value: "tipoEntrega",
                  type: Type.DADO.STRING,
                },
                {
                  value: "dataCadastro",
                  type: Type.DADO.DATE,
                },
                {
                  value: "posicao",
                  type: Type.DADO.STRING,
                },
                {
                  value: "dataUltimoPedido",
                  type: Type.DADO.DATE,
                },
                {
                  value: "dataProximoPedido",
                  type: Type.DADO.DATE,
                },
                {
                  value: "quantidadePedidoGeradosFiltro",
                  value2: "quantidadePedidoGerados",
                  type: Type.DADO.VALUE,
                },
                {
                  value: "valorTotalAssinatura",
                  type: Type.DADO.STRING,
                },
                {
                  msgError: true,
                  value: "erroPedido",
                  error: "mensagemPedido",
                },
              ]}
              bordered
              //search
              orientation="center"
              edit
              sizeEdit="2"
              filter={this.renderFiltros()}
              handlerEdit={(rede) => this.editarAssinatura(rede)}
              //    handlerDetail={(rede) => this.carrinhoAssinatura()}
              //  detail
              iconDeDetail={Type.ICON.SALE_CART}
              data={assinaturas}
              error="Nenhuma Assinatura encontrada!"
            ></Table>
          </Div>
        </Div>
      </>
    );
  }
}

export default withRouter(AssinaturasListar);
