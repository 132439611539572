import React, { useState } from "react";
import { Div, Label } from "../components";

const styles = {
  title: {
    color: "#46160E",
    fontSize: window.innerWidth > 600 ? "25px" : "20px",
    marginLeft: 10,
    fontWeight: "bold",
  },
  labelDepartamento: {
    fontSize: 18,
    fontWeight: "bold",
  },
  imageIcon: {
    width: 28,
    height: 28,
    marginBottom: 15,
    marginLeft: 5,
    marginRight: 5,
  },

  marcasEstoque: {
    borderTop: "1px solid #e6e6e6",
    borderLeft: "1px solid #e6e6e6",
    float: "left",
    width: "16.666667%",
    textAlign: "center",
    height: "122px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default function ContainerMarcas({ corSecundaria }) {
  const [marcas, setMarcas] = useState([1, 2, 3, 4, 5, 6, 7]);

  return (
    <Div style={{ width: 1150, marginTop: 20, padding: 30 }}>
      <Div inline="start" pointer>
        <Label
          pointer
          family="SemiBold"
          marginLeft="2"
          style={{ ...styles.labelDepartamento, color: corSecundaria }}
          value={"Marcas em Estoque"}
        />
      </Div>

      {marcas.map((item, index) => {
        let borderBottom =
          index > 5 || marcas.length >= 5 ? "1px solid #e6e6e6" : "none";
        let borderRight =
          index === 5 || marcas.length === index + 1
            ? "1px solid #e6e6e6"
            : "none";
        return (
          <Div
            style={{
              ...styles.marcasEstoque,
              borderBottom,
              borderRight,
            }}
          >
            <a href="#">
              <img
                width="122"
                // height="122"
                alt="Marcas da loja"
                title="Marcas da loja"
                loading="lazy"
                data-src="https://www.imgs.lojainstantanea.com.br/mercasimples/b73803e4-b193-11eb-8320-0242ac320002/geral/20230109-logoempresa.webp"
                class="img-responsive lazyloaded"
                src="https://www.imgs.lojainstantanea.com.br/mercasimples/b73803e4-b193-11eb-8320-0242ac320002/geral/20230109-logoempresa.webp"
              />
            </a>
          </Div>
        );
      })}
    </Div>
  );
}
