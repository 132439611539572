import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setListasDeCompra } from "../../redux/actions";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Color, Http, Mask, Routes, Type, Utils } from "../../utilities";
import "./listaCompra.css";
import {
  Button,
  Checkbox,
  Div,
  Form,
  I,
  If,
  Image,
  InputDefault,
  InputGroup,
  Label,
  ModalNotification,
  Row,
  SectionButtonForm,
  SectionForm,
  TextArea,
  Title,
} from "../../components";
import FileUpload from "../../components/forms/input/FileUpload";
import ImagemIndisponivel from "../../assets/images/imgindisponivel_thumb.webp";
import CarrinhoUtils from "../../views/utils/CarrinhoUtils";
import { ProdutoUtils } from "../../views";

class ListaCompraDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.location.state,
      corPrimaria: Color.EMPRESA.PRIMARIA,
      editarDescricao: false,
      listas: [],
      listaSelecionada: {},
      showResultadosPesquisa: false,
      textoPesquisa: null,
      produtosPesquisa: [],
      produtosSelecionadosPesquisa: new Map(),
      sucessoPesquisa: true,
      nomeNovaLista: null,
      listaAlterada: false,
      assinaturaLista: "Assinar lista",
      assinaturaListaModal: "Assinar lista",
      assinaturaAtivaModal: false,
      pesquisa: "",
      listaIndex: 999,
      isAssinatura: true,
      assinaturaAtiva: false,
      listaCompraAssinatura: [],
      paramentrosAssinatura: {},
      listaPesquisa: [],
      motivoCancelamento: "",
      modificado: {},
    };
  }

  componentDidMount() {}

  async submit(event) {
    event.preventDefault();
  }

  getQuantidadeLabel(produto) {
    let preco = produto.precoVenda;
    let quantidade = produto.quantidade || 0;
    return produto.possuiQuantidadeFracionada
      ? `${Number(produto.quantidade)}g`
      : `${quantidade}un x ${Mask.formatValueBr(preco)}`;
  }

  addQuantidadeInicialPesquisa(item) {
    let quantidade = Number(item.quantidade || 0);

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = Number(item.fatorEmGrama);
    } else {
      quantidade = 1;
    }

    item.quantidade = quantidade;
    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    let produtosSelecionadosPesquisa = new Map(
      this.state.produtosSelecionadosPesquisa
    );
    produtosSelecionadosPesquisa.set(item.codigoBarras, item);

    this.setState({ produtosSelecionadosPesquisa }, () =>
      this.renderPesquisarProdutos()
    );
  }

  listarProdutosPesquisa() {
    Http.get(`/busca/${this.state.textoPesquisa}`)
      .then(({ status, data }) => {
        if (status) {
          this.setState(
            {
              produtosPesquisa: data.listaProdutos,
              sucessoPesquisa: data.sucessoPesquisa,
            },
            () => this.renderPesquisarProdutos()
          );
        }
      })
      .catch((error) => console.log(error));
  }

  getProdutosSelecionados() {
    let itens = [];
    let produtosSelecionadosPesquisa = new Map(
      this.state.produtosSelecionadosPesquisa
    );
    produtosSelecionadosPesquisa.forEach((p) => itens.push(p));
    return itens;
  }

  deletarListaCompra(lista) {
    let removerLista = {
      idListaCompra: lista.idListaCompra,
      idListaCompraAssinatura: lista.assinatura
        ? lista.assinatura.idListaCompraAssinatura
        : null,
      motivoCancelamento: this.state.motivoCancelamento,
    };

    Http.delete("/assinatura/listacompra", removerLista)
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            this.consultarListasDeCompra();
          } else {
            ModalNotification.renderMessage({
              title: "Ocorreu um erro ao remover a lista",
              body: "Falha ao remover a lista",
              handlerCancel: () => {},
              labelButtonCancel: "Fechar",
            });
          }
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  solicitarRemoverLista(lista) {
    let assinatura = lista.assinatura;
    let data = "";

    if (assinatura) {
      data = moment(assinatura.dataPrimeiroPedido).format("DD/MM/YYYY");
    }

    let textoModal =
      lista.itens.length > 0
        ? assinatura
          ? `Essa lista de compra está assinada desde do dia ${data}, a exclusão da lista de compra cancelará sua assinatura. Deseja continuar?`
          : `Essa lista possui ${lista.itens.length} itens que facilita as suas compras futuras. Deseja continuar?`
        : "Essa lista de compras será removida do sistema, deseja remover a lista";

    ModalNotification.renderMessage({
      title: "Remover Lista de Compras",
      body: (
        <Div col="12">
          <Label style={{ marginBottom: "17px" }} value={textoModal} />
          <TextArea
            paddingLeft="0"
            paddingRight="0"
            responsive="12"
            placeHolder="Preencha caso tenha alguma observação do motivo do cancelamento"
            style={{ minHeight: "124px" }}
            handlerChange={(e) => {
              this.setState({
                motivoCancelamento: e.target.value,
              });
            }}
          />
        </Div>
      ),
      handlerCancel: () => {},
      handlerConfirm: () => this.deletarListaCompra(lista),
    });
  }

  colocarProdutosNoCarrinho(lista) {
    //let itens = JSON.parse(Utils.isNotUndefined(lista) ? JSON.stringify(lista.itens) : JSON.stringify(this.state.listaSelecionada.itens));
    //console.log("colocar no carrinho", this.state.listaSelecionada.itens)
    let itens = lista.itens;

    if (Utils.isValueValid(itens)) {
      Array.from(itens).forEach((item) => {
        CarrinhoUtils.addQuantidadeItemJaNoCarrinhoLista(
          this.props.carrinho,
          item
        );
        CarrinhoUtils.adicionarItemNoCarrinhoDeCompras(
          item,
          this.props.carrinho,
          this.props.atualizarCarrinho,
          null,
          this.state.parametrosPedido
        );
      });
      this.props.history.push(Routes.CARRINHO, {
        parametros: this.state.parametrosPedido,
        logoEmpresa: this.state.urlFotoEmpresa,
        empresa: this.state.empresa,
      });
    } else {
      ModalNotification.renderMessage({
        title: "Mensagem",
        body: (
          <Label value="Nenhum produto na lista para colocar no carrinho!" />
        ),
        handlerCancel: () => {},
        labelButtonCancel: "Fechar",
      });
    }
  }

  atualizarListaCompra(pesquisa = false) {
    Http.put("/listacompra", this.getListaCompraAtualizacao())
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            this.setState({
              produtosSelecionadosPesquisa: new Map(),
              produtosPesquisa: [],
              listaAlterada: false,
            });
            if (!pesquisa) {
              ModalNotification.renderMessage({
                title: "Mensagem",
                body: <Label value="Produtos adicionados com sucesso!" />,
                handlerCancel: () => {
                  Utils.refreshPage();
                },
                labelButtonCancel: "Fechar",
              });
            }
          } else {
            ModalNotification.renderMessage({
              title: "Falha ao atualizar lista de compras",
              body: <Label value={data.mensagem} />,
              handlerCancel: () => {},
            });
          }
        }
      })
      .catch((error) => console.log(error));
  }

  renderPesquisarProdutos() {
    let produtos = this.state.produtosPesquisa;
    let produtosSelecionadosPesquisa = new Map(
      this.state.produtosSelecionadosPesquisa
    );

    ModalNotification.renderMessage({
      title: "Selecione os produtos para adicionar na lista",
      responsive: "10",
      body: (
        <Div
          className="d-flex align-items-start justify-content-start align-self-start"
          style={{ width: "100%" }}
        >
          <Div col="7">
            <InputGroup
              prepend={false}
              append
              placeholder="Nome do produto"
              value={this.state.textoPesquisa}
              icon={Type.ICON.SEARCH}
              label="Pesquise produtos para adicionar na lista"
              handlerChange={(e) => {
                this.setState(
                  { textoPesquisa: e.target.value, sucessoPesquisa: true },
                  () => this.renderPesquisarProdutos()
                );
              }}
              handlerKeyUp={(e) => {
                if (e.keyCode === 13) this.listarProdutosPesquisa();
              }}
              handlerIcon={() => this.listarProdutosPesquisa()}
            />
            <If and value1={!this.state.sucessoPesquisa}>
              <Label
                value={`Produto não encontrado para a pesquisa: '${this.state.textoPesquisa}'`}
                family="Light"
                style={{ color: this.state.corPrimaria }}
              />
            </If>
            <Div style={{ height: window.innerHeight / 2, overflow: "auto" }}>
              {Array.from(produtos).map((produto, index) => {
                return (
                  <Div inline="between" key={index} className="border-bottom">
                    <Div inline="start">
                      <Image
                        styleImage={{ width: 70, height: 70 }}
                        src={produto.urlImagemPrincipalThumbnail}
                      />
                      <Label
                        family="Light"
                        value={produto.descricao}
                        className="text-ellipsis"
                        style={{ width: 200 }}
                      />
                      <Label
                        family="Light"
                        marginLeft="2"
                        style={{ color: this.state.corPrimaria, fontSize: 20 }}
                        value={Mask.formatValueBr(produto.precoVenda)}
                      />
                    </Div>
                    <Checkbox
                      handlerChange={({ checked }) => {
                        if (checked) {
                          produtosSelecionadosPesquisa.set(
                            produto.codigoBarras,
                            produto
                          );
                        } else {
                          produtosSelecionadosPesquisa.delete(
                            produto.codigoBarras
                          );
                        }
                        this.setState({ produtosSelecionadosPesquisa }, () => {
                          if (checked) {
                            this.addQuantidadeInicialPesquisa(produto);
                          } else {
                            this.renderPesquisarProdutos();
                          }
                        });
                      }}
                      checked={Utils.isValueValid(
                        produtosSelecionadosPesquisa.get(produto.codigoBarras)
                      )}
                      marginLeft="3"
                    />
                  </Div>
                );
              })}
            </Div>
          </Div>
          <Div
            col="5"
            className="border-left border-right"
            style={{ height: window.innerHeight / 1.8, overflow: "auto" }}
            column
          >
            <Label
              value="Produtos selecionados"
              family="Light"
              style={{ color: Color.ECOMMERCE.CINZA_ESCURO }}
            />
            <If and value1={produtosSelecionadosPesquisa.size === 0}>
              <Label
                value="Nenhum produto selecionado"
                family="Light"
                style={{ color: this.state.corPrimaria }}
              />
            </If>
            <If and value1={produtosSelecionadosPesquisa.size > 0}>
              {this.getProdutosSelecionados().map((produto, index) => {
                return (
                  <Div inline="between" key={index} className="border-bottom">
                    <Div inline="start" style={{ width: "100%" }}>
                      <Div col="4" column>
                        <Image
                          styleImage={{ width: 50, height: 50 }}
                          src={produto.urlImagemPrincipalThumbnail}
                        />
                        <Label
                          family="Light"
                          value={produto.descricao}
                          className="text-ellipsis"
                          style={{ width: 150 }}
                        />
                      </Div>
                      <Div inline="center" col="6">
                        <I
                          icon={Type.ICON.MINUS}
                          pointer
                          style={{
                            color: this.state.corPrimaria,
                            fontSize: 20,
                          }}
                          handlerClick={() => {
                            if (
                              Utils.isValueValid(produto.quantidade) &&
                              produto.quantidade > 0
                            ) {
                              this.removeQuantidadeItemPesquisa(produto);
                            }
                          }}
                        />
                        <Label
                          value={this.getQuantidadeLabel(produto)}
                          family="Light"
                          margin="3"
                          className="mx-auto"
                        />
                        <I
                          icon={Type.ICON.PLUS}
                          pointer
                          style={{
                            color: this.state.corPrimaria,
                            fontSize: 20,
                          }}
                          handlerClick={() => {
                            this.addQuantidadeItemPesquisa(produto);
                          }}
                        />
                      </Div>
                      <Div col="2">
                        <Label
                          family="Light"
                          marginLeft="2"
                          style={{
                            color: this.state.corPrimaria,
                            fontSize: 15,
                          }}
                          value={Mask.formatValueBr(produto.valorSubTotalItem)}
                        />
                      </Div>
                    </Div>
                  </Div>
                );
              })}
            </If>
          </Div>
        </Div>
      ),
      handlerConfirm: () => {
        if (produtosSelecionadosPesquisa.size > 0) {
          this.atualizarListaCompra();
        } else {
          this.setState({
            produtosPesquisa: [],
            textoPesquisa: "",
          });
        }
      },
      handlerCancel: () => {
        this.setState({
          produtosSelecionadosPesquisa: new Map(),
          produtosPesquisa: [],
          textoPesquisa: "",
        });
      },
    });
  }

  renderProdutos = (lista) => {
    let { corPrimaria, listaAlterada, modificado } = this.state;
    let produtos = Array.from(lista.itens || []);
    let plural = `${Object.keys(modificado).length < 2 ? "" : "s"}`;
    let textoModificado = `${
      Utils.isNotObjectEmpty(modificado) ? Object.keys(modificado).length : 0
    } produto${plural} modificado${plural}`;

    let totalItens = produtos
      .map((p) => Number(p.precoVenda * p.quantidade))
      .reduce((a, b) => a + b, 0);

    let idParent = Utils.uuidString();
    return (
      <Div
        col="12"
        id={idParent}
        padding="2"
        bg={Color.NODE.WHITE}
        shadow
        rounded
        className="border border-left-primary"
      >
        <Div col="12" inline="between">
          {/* <If and value1={this.state.editarDescricao}>
            <InputDefault
              responsive="8"
              handlerChange={(e) => {
                this.setState({
                  listaSelecionada: {
                    ...lista,
                    descricao: e.target.value,
                  },
                  listaAlterada: true,
                });
              }}
              value={lista.descricao}
            />
          </If>

          <If and value1={!this.state.editarDescricao}>
            <Div col="12" inline="start" >
              <Label
                family="Light"
                value={lista.descricao}
                className="text-ellipsis"
                style={{ color: Color.ECOMMERCE.CINZA_ESCURO, fontSize: 18, margin: "15px", fontWeight: "bolder" }}
              />

              <I
                icon={Type.ICON.EDIT}
                pointer
                style={{ fontSize: 25, color: Color.ECOMMERCE.CINZA_ESCURO }}
                title="Editar nome da lista"
                handlerClick={() => this.setState({ editarDescricao: !this.state.editarDescricao })}
              />
            </Div>
          </If> */}
        </Div>
        <Div
          inline="start"
          style={{ marginLeft: "25px", marginBottom: "20px" }}
        >
          <Div
            inline="center"
            className="border m-1"
            style={{ padding: "5px 20px" }}
            rounded
            pointer
            handlerClick={() => this.renderPesquisarProdutos()}
          >
            <I
              icon={Type.ICON.PLUS_SQUARE}
              pointer
              style={{ fontSize: 25, color: corPrimaria }}
            />
            <Label
              family="Light"
              value="Adicionar produto"
              pointer
              marginTop="2"
              marginLeft="2"
            />
          </Div>

          <Div inline="center">
            {/* <Div
              inline="center"
              className="border m-1"
              rounded
              pointer
              style={{ padding: "5px 20px" }}
              handlerClick={() => this.colocarProdutosNoCarrinho(this.state.listaSelecionada)}
            >
              <I
                icon={Type.ICON.SALE_CART}
                pointer
                style={{ fontSize: 25, color: corPrimaria }}
              />
              <Label
                family="Light"
                value="Finalizar Pedido"
                pointer
                marginTop="2"
                marginLeft="2"
              />
            </Div> */}
            {Utils.isValueValid(produtos) && (
              <Div
                inline="center"
                className="border "
                rounded
                pointer
                handlerClick={() =>
                  Utils.isValueValid(produtos)
                    ? this.atualizarListaCompra()
                    : this.solicitarRemoverLista(lista)
                }
                style={{
                  backgroundColor: listaAlterada
                    ? this.state.corPrimaria
                    : "#f2f2f2",
                  color: listaAlterada ? "white" : Color.ECOMMERCE.CINZA_ESCURO,
                  opacity: 0.9,
                  marginLeft: 10,
                  padding: "5px 20px",
                }}
              >
                <I
                  icon={
                    Utils.isValueValid(produtos)
                      ? Type.ICON.REFRESH
                      : Type.ICON.CLOSEX
                  }
                  pointer
                  style={{ fontSize: 25 }}
                />
                <Label
                  family="Light"
                  value={
                    Utils.isValueValid(produtos)
                      ? "Confirmar Atualização"
                      : "Remover lista"
                  }
                  pointer
                  marginTop="2"
                  marginLeft="2"
                />
              </Div>
            )}
            <If and value1={Object.keys(modificado).length >= 1}>
              <Label
                family="Light"
                value={textoModificado}
                pointer
                style={{
                  color: Color.ECOMMERCE.LARANJA,
                  fontWeight: "bolder",
                }}
                marginTop="2"
                marginLeft="3"
              />
            </If>
          </Div>
        </Div>
        {produtos.map((prod, index) => {
          return (
            <Div key={index}>
              <Div
                inline="between"
                padding="2"
                col="12"
                style={{ borderBottom: "1px solid #f2f2f2" }}
              >
                <Div inline="start" col="6">
                  <Image
                    border
                    src={prod.urlImagemPrincipalThumbnail}
                    width="70px"
                  />
                  <Label
                    className="text-ellipsis descricao-item"
                    value={prod.descricao}
                    marginLeft="2"
                    family="Light"
                    style={
                      prod.quantidade > 0 ? { width: "70%" } : { width: "70%" }
                    }
                  />
                </Div>
                <Div inline="center" col="3">
                  <I
                    icon={Type.ICON.MINUS}
                    pointer
                    style={{ color: corPrimaria, fontSize: 20 }}
                    handlerClick={() => {
                      if (prod.quantidade > 0) {
                        this.removeQuantidadeItemLista(lista, prod);
                      }

                      if (prod.quantidade === 0) {
                        ModalNotification.renderMessage({
                          title: "Remover Produto da Lista de Compras",
                          body: "Esse Produto será removido da lista de compras. Deseja continuar?",
                          handlerCancel: () => {
                            this.addQuantidadeItemLista(lista, prod);
                          },
                          handlerConfirm: () => this.deletarProdutoLista(prod),
                        });
                      }

                      if (modificado[index]) {
                        if (modificado[index].quantidade === prod.quantidade) {
                          delete modificado[index];
                        }
                      }
                    }}
                  />
                  <Label
                    value={this.getQuantidadeLabel(prod)}
                    family="Light"
                    margin="3"
                    className="mx-auto"
                  />
                  <I
                    icon={Type.ICON.PLUS}
                    pointer
                    style={{ color: corPrimaria, fontSize: 20 }}
                    handlerClick={() => {
                      this.addQuantidadeItemLista(lista, prod);
                      modificado[index] = prod;
                    }}
                  />
                </Div>
                <Div col="3">
                  <Label
                    value={Mask.formatValueBr(Number(prod.valorSubTotalItem))}
                    col="1"
                    family="SemiBold"
                    style={{ color: corPrimaria }}
                  />
                </Div>
                <I
                  icon={Type.ICON.CLOSEX}
                  style={{ position: "absolute", right: 20, color: "red" }}
                  pointer
                  handlerClick={() => this.solicitarRemocaoProdutoLista(prod)}
                />
              </Div>
            </Div>
          );
        })}
        <Div
          inline="between"
          style={{ width: "99%", backgroundColor: "#f2f2f2" }}
          margin="2"
          padding="2"
          shadow
          className="border"
          rounded
        >
          <Label
            value="Total da lista"
            style={{
              color: Color.ECOMMERCE.CINZA_ESCURO,

              fontSize: 20,
            }}
            family="Bold"
          />
          <Label
            value={Mask.formatValueBr(Number(totalItens))}
            style={{ color: Color.ECOMMERCE.CINZA_ESCURO, fontSize: 20 }}
            family="Bold"
          />
        </Div>
      </Div>
    );
  };

  render() {
    const { listas } = this.state;
    return (
      <Div
        padding="2"
        paddingRight="5"
        marginLeft="3"
        bg={Color.NODE.WHITE}
        col="12"
        shadow
      >
        <Row col="12" padding="3">
          <Title value="Lista de Compras" type="h4" family="Bold" />
        </Row>

        <Form shadow id="formCadastroConcorrente" handlerSubmit={this.submit}>
          <SectionForm shadow borderLeft="primary">
            <Row col="8">
              <InputDefault
                required
                label="Descrição"
                name="descricao"
                responsive="8"
                maxLength="200"
                value={this.state.descricao}
                handlerChange={(e) => {
                  this.setState({ descricao: e.target.value });
                }}
              />
            </Row>

            <Row col="12">
              <Div col="4" style={{ marginTop: "36px" }}>
                <Div col="10" style={{ textAlign: "center" }}>
                  <Image
                    src={
                      this.state.urlFoto
                        ? this.state.urlFoto
                        : ImagemIndisponivel
                    }
                    styleImage={{ maxWidth: "100%" }}
                    //className="color-icon-menu"
                    pointer
                  />
                </Div>
                <FileUpload
                  col="10"
                  isIcon
                  label="Foto Lista de Compra"
                  handlerChange={(e) =>
                    this.handleFileUpload({
                      name: "urlFoto",
                      value: e.fileUpload,
                    })
                  }
                  classNameIcon="col-12 btn bg-primary text-white rounded pt-3"
                  name="urlFoto"
                  tipos={["png"]}
                ></FileUpload>
              </Div>
            </Row>

            <SectionButtonForm
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                btn={Color.NODE.DANGER}
                value="Cancelar"
                col="1"
                handlerClick={(_e) => this.listaConcorrente()}
                margin="1"
              />

              <Button
                btn={Color.NODE.PRIMARY}
                value="Confirmar"
                col="1"
                margin="1"
              />
            </SectionButtonForm>
          </SectionForm>
        </Form>

        <Div marginLeft="2" paddingTop="2" paddingBottom="2" marginTop="4">
          {this.renderProdutos(listas)}
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.empresaState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setListasDeCompra }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListaCompraDashboard)
);
